import {MediaDTO} from './observationsModels';

export type VendorTreeInheritance = 'VENDOR' | 'SITE' | 'ACTOR' | 'ACTIVITY' | 'NONE';
export type DeviceStatus = 'ASSIGNED' | 'READY_FOR_USE' | 'NEEDS_INSPECTION' | 'RETIRED' | 'REMOVED_FROM_INSTANCE';

export type EquipmentKitStatus = 'READY_FOR_USE' | 'READY_FOR_INSPECTION' | 'INCOMPLETE' | 'ASSIGNED' | 'RETIRED';

export type AdHocTaskStatusParameter = 'reopen' | 'finish' | 'cancel';

export type VendorSubstatus =
    | 'Vendor not contacted'
    | 'Initiated communication'
    | 'One or more vendors not available'
    | 'Referral sent with orders still pending'
    | 'Referral document with orders sent'
    | 'Confirmed referral receipt'
    | 'N/A';

export type HandOffStatus =
    | 'Not started'
    | 'In progress'
    | 'Not fulfilled'
    | 'Completed'
    | 'Cancelled'
    | 'Pending Cancellation';

export type HandOffUrgency = 'Stat' | 'ASAP' | 'Routine';

export type InHomeAppointmentStatus =
    | 'Scheduled'
    | 'Confirmed'
    | 'Checked In'
    | 'Check-in started'
    | 'Check-in ready for review'
    | 'Check-out started'
    | 'Check-out ready for review'
    | 'Checked Out'
    | 'Pending Cancellation'
    | 'Cancelled'
    | 'Completed'
    | 'Removed';

export type CancellationRequestStatus = 'Pending Cancellation' | 'Completed';

export type Frequency =
    | 'ONCE'
    | 'DAILY'
    | 'Q24H'
    | 'BID'
    | 'Q12H'
    | 'TID'
    | 'Q8H'
    | 'QID'
    | 'Q6H'
    | 'Q4H'
    | 'Q3H'
    | 'Q2H'
    | 'Q1H'
    | 'EVERY_OTHER_DAY'
    | 'QOD'
    | 'Q3D'
    | 'Q5D'
    | 'WEEKLY'
    | 'Q2W'
    | 'Q15D'
    | 'Q3W'
    | 'Q4W'
    | 'Q30D'
    | 'PO'
    | 'CONT'
    | 'UNKNOWN';

export type FollowupAction =
    | 'Select Vendor'
    | 'Schedule'
    | 'Confirm'
    | 'Check in'
    | 'Check out'
    | 'Document'
    | 'Completed';

export type PatientTypeOptionsDTO = 'Under assessment' | 'Demo' | 'Live' | 'Pending';

export type MissedCheckInOutReportOption =
    | 'SP_ARRIVED_CIN_COUT_NOT_RECORDED_IN_CESIA'
    | 'SP_ARRIVED_BUT_DID_NOT_DO_CIN_COUT'
    | 'SP_NO_SHOW'
    | 'PATIENT_REFUSED_APPOINTMENT'
    | 'CIN_COUT_NOT_APPROPRIATE_FOR_APPOINTMENT';

export type UnavailableServiceProvider =
    | 'Out of service hours'
    | 'No availability at necessary time'
    | 'Could not reach'
    | 'Service request contained an error'
    | 'Provider Reached - Declined Appointment'
    | 'No Answer / No Response';

export type OrderState =
    | 'ACTIVE'
    | 'WAITING_CANCEL_ACKNOWLEDGE'
    | 'CANCELED_WAITING_ACTIVITIES_COMPLETION'
    | 'WAITING_UPDATE_ACKNOWLEDGE'
    | 'IGNORED'
    | 'CANCELLED'
    | 'DONE';

export type MessageType = 'ADT' | 'ORM' | 'RDE';

export enum ShiftRole {
    CHARGE_NURSE = 'CHARGE_NURSE',
    ACQUISITION_NURSE = 'ACQUISITION_NURSE',
    SENIOR_SERVICE_COORDINATOR = 'SENIOR_SERVICE_COORDINATOR',
}

export type ShiftDTO = {
    personnel: PersonnelGeneralInfoDTO;
    clusters: ClusterDTO[];
    role: ShiftRole;
    shiftStart: string;
    shiftEnd: string;
    id: number;
    shiftEndReason?: string;
    endTimestamp?: string;
};

export type CreateShiftDTO = {
    personnelId: number;
    clusterIds: number[];
    role: ShiftRole;
    shiftStart: string;
    shiftEnd: string;
};

export type PagePatientDropdownDataDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: PatientDropdownDataDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: PageableObject;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type PatientDropdownDataDTO = {
    id: number;
    firstName: string;
    lastName: string;
    preferredName?: string;
    timeZone: string;
    dob: string;
    admitDate: string;
    patientType: PatientTypeOptionsDTO;
};

export type PatientBasicDataDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    dob?: string;
    sex?: 'Male' | 'Female' | 'Other' | 'Unknown';
    homeAddress?: AddressDTO;
    mrn?: string;
    sponsor?: string;
    timeZone?: string;
    assignedNurse?: Personnel;
    assignedServiceCoordinator?: Personnel;
    active?: boolean;
};
export type ApiError = {
    code?: string;
    /** Format: date-time */
    timestamp?: string;
    message?: string;
    details?: ErrorDetails[];
};
export type ErrorDetails = {
    object?: string;
    field?: string;
    rejectedValue?: {[key: string]: unknown};
    message?: string;
    properties?: {[key: string]: {[key: string]: unknown} | string | number | Array<number>};
};
export type ErrorResponse = {
    code?: string;
    message?: string;
    properties?: {[key: string]: {[key: string]: unknown}};
};
export type LanguageDTO = {
    name?: string;
    code?: string;
};
export type EducationDocumentFileDTO = {
    /** Format: int64 */
    id?: number;
    title?: string;
    fileName?: string;
    language?: LanguageDTO;
    enabled?: boolean;
    defaultFile?: boolean;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
};
export type EducationDocumentPackageEditDTO = {
    title?: string;
    type?: 'Program Information' | 'Patient Education';
    archived?: boolean;
    sponsors?: string[];
    products?: string[];
    documentFiles?: EducationDocumentFileDTO[];
};
export type EducationDocumentPackageDTO = {
    id?: number;
    title?: string;
    type?: 'Program Information' | 'Patient Education';
    sponsors?: string[];
    products?: string[];
    educationDocumentFiles?: EducationDocumentFileDTO[];
    archived?: boolean;
    shortHistory?: ShortHistoryItem[];
};
export type ShortHistoryItem = {
    /** Format: date-time */
    dateTime?: string;
    action?: string;
    fullName?: string;
};
export type AddUnplannedActivitiesRequestDTO = {
    activityIds: number[];
    tbdActivityIds: number[];
    prnActivities: PlanAppointmentPRNActivityRequestDTO[];
    step?:
        | 'From Service Request'
        | 'Auto-append'
        | 'Check In'
        | 'Check Out'
        | 'Confirm'
        | 'Edit'
        | 'Check In Review'
        | 'Check Out Review';
    shouldNotCreateServiceRequest?: boolean;
};

export type PlanAppointmentPRNActivityRequestDTO = {
    orderId?: number;
    templateId?: number;
};

export type ActionConfiguration = {
    enabled?: boolean;
    customPropertyMap?: {[key: string]: string};
};
export type Address = {
    streetAddress?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    state?: string;
    zipCode?: string;
};
export type Contact = {
    contactType?: string;
    contactPriority?: 'Regular' | 'Escalation';
    sequence?: number;
    comment?: string;
    phone?: string;
    email?: string;
    website?: string;
    name?: string;
};
export type DaySchedule = {
    startTime?: string;
    endTime?: string;
};
export type GeographyJson = {
    everywhere?: boolean;
    towns?: Town[];
    zips?: string[];
    serviceAreas?: number[];
};
export type InheritedInfoAddress = {
    data?: Address;
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoBoolean = {
    data?: boolean;
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoGeographyJson = {
    data?: GeographyJson;
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoInteger = {
    data?: number;
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoListContact = {
    data?: Contact[];
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoListSupportingDocumentReference = {
    data?: SupportingDocumentReference[];
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoMapIntegrationActionActionConfiguration = {
    data?: {[key: string]: ActionConfiguration};
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoScheduleJson = {
    data?: ScheduleJson;
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoSelfCheckOption = {
    data?: string;
    inheritance?: VendorTreeInheritance;
};
export type InheritedInfoSetString = {
    data?: string[];
    inheritance?: VendorTreeInheritance;
};
export type LocalTime = {
    hour?: number;
    minute?: number;
    second?: number;
    nano?: number;
};
export type SPIActionDTO = {
    type?: 'SEND_REFERRAL_DOCUMENTATION' | 'SEND_APPOINTMENT_REQUEST';
};
export type ScheduleJson = {
    daySchedule?: {[key: string]: DaySchedule};
};
export type SupportingDocumentReference = {
    title?: string;
    description?: string;
    /** Format: url */
    url?: string;
    precedence?: number;
    /** Format: date */
    effectiveDate?: string;
};
export type Town = {
    state?: string;
    name?: string;
    partOf?: Town;
};
export type EnabledIntegrationDTO = {
    siteCode?: string;
    siteName?: string;
    type?: 'SEND_REFERRAL_DOCUMENTATION' | 'SEND_APPOINTMENT_REQUEST';
    configuration?: ActionConfiguration;
};
export type VendorDTO = {
    payors?: InheritedInfoSetString;
    sponsors?: InheritedInfoSetString;
    schedulingHours?: InheritedInfoScheduleJson;
    serviceHours?: InheritedInfoScheduleJson;
    geo?: InheritedInfoGeographyJson;
    address?: InheritedInfoAddress;
    contacts?: InheritedInfoListContact[];
    documents?: InheritedInfoListSupportingDocumentReference[];
    availableForScheduling?: boolean;
    instructions?: string;
    priority?: InheritedInfoInteger;
    selfCheckInOption?: InheritedInfoSelfCheckOption;
    selfCheckOutOption?: InheritedInfoSelfCheckOption;
    spiEnabled?: InheritedInfoBoolean;
    spiActions?: InheritedInfoMapIntegrationActionActionConfiguration;
    code?: string;
    vendorName?: string;
    availableSPIActions?: SPIActionDTO[];
    enterpriseId?: string;
    enabledIntegrations?: EnabledIntegrationDTO[];
    serviceNowIntegrationEnabled?: boolean;
};
export type CreatedResponseDTO = {
    id?: number;
};
export type SiteDTO = {
    payors?: InheritedInfoSetString;
    sponsors?: InheritedInfoSetString;
    schedulingHours?: InheritedInfoScheduleJson;
    serviceHours?: InheritedInfoScheduleJson;
    geo?: InheritedInfoGeographyJson;
    address?: InheritedInfoAddress;
    contacts?: InheritedInfoListContact[];
    documents?: InheritedInfoListSupportingDocumentReference[];
    availableForScheduling?: boolean;
    instructions?: string;
    priority?: InheritedInfoInteger;
    selfCheckInOption?: InheritedInfoSelfCheckOption;
    selfCheckOutOption?: InheritedInfoSelfCheckOption;
    spiEnabled?: InheritedInfoBoolean;
    spiActions?: InheritedInfoMapIntegrationActionActionConfiguration;
    code?: string;
    siteName?: string;
    availableSPIActions?: SPIActionDTO[];
    enterpriseId?: string;
    serviceNowIntegrationEnabled?: boolean;
};
export type ActivitySpecificationDTO = {
    templateId?: number;
    code?: string;
    name?: string;
    showOnPatient?: boolean;
    expectedDuration?: string;
    expectedDurationSecondary?: string;
    defaultAppointmentArrivalWindow?: string;
    maxEarlyArrival?: string;
    cardinality?: number;
    supplyChainCategory?: string;
    manualArrivalWindow?: boolean;
};
export type ActorDTO = {
    payors?: InheritedInfoSetString;
    sponsors?: InheritedInfoSetString;
    schedulingHours?: InheritedInfoScheduleJson;
    serviceHours?: InheritedInfoScheduleJson;
    geo?: InheritedInfoGeographyJson;
    address?: InheritedInfoAddress;
    contacts?: InheritedInfoListContact[];
    documents?: InheritedInfoListSupportingDocumentReference[];
    availableForScheduling?: boolean;
    instructions?: string;
    priority?: InheritedInfoInteger;
    selfCheckInOption?: InheritedInfoSelfCheckOption;
    selfCheckOutOption?: InheritedInfoSelfCheckOption;
    actorCode?: string;
    actorName?: string;
    defaultActivities?: boolean;
    activities?: ActivitySpecificationDTO[];
    serviceNowIntegrationEnabled?: boolean;
    assignmentGroupId?: string;
};
export type ActivityDTO = {
    payors?: InheritedInfoSetString;
    sponsors?: InheritedInfoSetString;
    schedulingHours?: InheritedInfoScheduleJson;
    serviceHours?: InheritedInfoScheduleJson;
    geo?: InheritedInfoGeographyJson;
    address?: InheritedInfoAddress;
    contacts?: InheritedInfoListContact[];
    documents?: InheritedInfoListSupportingDocumentReference[];
    availableForScheduling?: boolean;
    instructions?: string;
    priority?: InheritedInfoInteger;
    activityCode?: string;
    activityName?: string;
};
export type ServiceAreaZipsDTO = {
    id?: number;
    name: string;
    description?: string;
    enabledInVendorDictionary: boolean;
    activeForPatients: boolean;
    type?: string;
    /** Format: date-time */
    updatedDate?: string;
    zipCodes: string[];
};
export type ServiceAreaTownsDTO = {
    id?: number;
    name: string;
    description?: string;
    enabledInVendorDictionary: boolean;
    activeForPatients: boolean;
    type?: string;
    /** Format: date-time */
    updatedDate?: string;
    towns: Town[];
};
export type GrandServiceAreaRequestDTO = {
    id?: number;
    name: string;
    description?: string;
    enabledInVendorDictionary: boolean;
    type?: string;
    /** Format: date-time */
    updatedDate?: string;
    includedAreaIds?: number[];
};
export type PatientPrecautionUpdateDTO = {
    endDate?: string;
};
export type PatientPrecautionDTO = {
    name: string;
    id: number;
    startDate: string;
    endDate?: string;
};
export type CreateTaskTemplateDTO = {
    name: string;
    instructions?: string;
    documents?: Document[];
    checklist?: TemplateChecklistDTO;
    editingNotes?: string;
    sponsors?: string[];
    products?: string[];
    payors?: string[];
    disabled?: boolean;
    category: string;
    linkedOrders?: OrderTaskTemplateDTO[];
};
export type Document = {
    title?: string;
    link?: string;
};
export type OrderTaskTemplateDTO = {
    orderCode: string;
    dueHours: number;
    product?: ProductDTO;
    isCustomOrder?: boolean;
};
export type TemplateChecklistDTO = {
    items?: TemplateChecklistItemDTO[];
};
export type TemplateChecklistItemDTO = {
    checked?: boolean;
    value?: string;
    checklist?: TemplateChecklistDTO;
};
export type TaskTemplateDTO = {
    id?: number;
    name?: string;
    instructions?: string;
    documents?: Document[];
    editingNotes?: string;
    sponsors?: string[];
    products?: string[];
    payors?: string[];
    checklist?: TemplateChecklistDTO;
    /** Format: date-time */
    updatedDate?: string;
    disabled?: boolean;
    category?: string;
    linkedOrders?: OrderTaskTemplateDTO[];
};
export type Checklist = {
    items?: ChecklistItem[];
};

export type ChecklistNested = {
    items?: {value?: string; checked?: boolean}[];
};

export type ChecklistItem = {
    value?: string;
    checked?: boolean;
    label?: string;
    answer?: string;
    checklist?: ChecklistNested;
};
export type UpdateStandardTaskDTO = {
    /** @example 2020-12-03 10:15 */
    dueDate: string;
    scratchPad?: string;
    shortName?: string;
    checklist?: Checklist;
};
export type StandardTaskResponseDTO = {
    id?: number;
    patientId?: number;
    name: string;
    /** Format: date-time */
    dueDate: string;
    taskState?: 'Open' | 'Started' | 'Cancelled' | 'Done';
    scratchPad?: string;
    taskType?: string;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    instructions?: string;
    documents?: Document[];
    checklist?: Checklist;
    category?: string;
    linkedOrder?: TaskLinkedOrderResponseDTO;
};
export type TaskLinkedOrderResponseDTO = {
    id?: number;
    orderName?: string;
    clinicalInfo?: string;
    comment?: string;
    frequency?: Frequency;
    originalFrequencyText?: string;
    /** Format: date */
    startDate?: string;
    /** Format: date */
    endDate?: string;
    providerFirstName?: string;
    providerLastName?: string;
};
export type CreateSimpleTaskDTO = {
    patientId: number;
    name: string;
    /** @example 2020-12-03 10:15 */
    dueDate: string;
    taskState?: 'Open' | 'Started' | 'Cancelled' | 'Done';
    scratchPad?: string;
    comment?: string;
};
export type SimpleTaskResponseDTO = {
    id?: number;
    patientId: number;
    name: string;
    /** Format: date-time */
    dueDate: string;
    taskState?: 'Open' | 'Started' | 'Cancelled' | 'Done';
    scratchPad?: string;
    taskType?: string;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    comment?: string;
};

export type ClusterDTO = {
    id?: number;
    name?: string;
};
export type PodDTO = {
    id?: number;
    name: string;
    clusterId: number;
    active?: boolean;
    cluster?: ClusterDTO;
};

export type DirectNumberDTO = {
    phoneNumber?: string;
    label: string;
};
export type BlockedNumberDTO = {
    phoneNumber?: string;
    note: string;
};
export type PersonnelWithRolesDTO = {
    id?: number;
    email?: string;
    preferredEmail?: string;
    firstName?: string;
    lastName?: string;
    degree?: 'Bachelor';
    clusterId?: number;
    clusters?: ClusterDTO[];
    sponsors?: string[];
    states?: StateResponseDTO[];
    statesSpecified?: boolean;
    archived?: boolean;
    roles: string[];
    /** @deprecated */
    routingProfileId?: string;
    routingProfile?: string;
    specificStatesEnabled?: boolean;
    roleIds?: string[];
};
export type StateResponseDTO = {
    name?: string;
    code?: string;
};
export type SimpleMessageResponseDTO = {
    notificationId?: string;
    status: 'OK';
    message?: string;
};
export type AddressDTO = {
    address1?: string;
    address2?: string;
    town?: Town;
    state?: string;
    zipCode?: string;
};
export type DischargeDestinationDTO = {
    code?: string;
    label?: string;
};
export type ElectricCompanyDTO = {
    companyName: string;
    phone?: string;
    extension?: number;
    comment?: string;
};
export type HospitalInfoDTO = {
    hospitalDays?: number;
    lengthOfStay?: number;
    lastBiometricReading?: number;
};
export type PatientContactDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    primaryLanguage?: LanguageDTO;
    relationship: 'EMERGENCY_CONTACT' | 'CAREGIVER' | 'SPOUSE';
    phoneNumber: string;
};
export type PatientContactInfoDTO = {
    mobilePhoneNumber?: PatientPhoneNumberDTO;
    homePhoneNumber?: PatientPhoneNumberDTO;
};
export type SexualOrientation =
    | 'Straight or heterosexual'
    | 'Lesbian, gay or homosexual'
    | 'Bisexual'
    | 'Queer, pansexual, and/or questioning'
    | 'Don`t know'
    | 'Decline to answer'
    | 'Something else, please describe';
export type Races =
    | 'White'
    | 'Black or African American'
    | 'American Indian or Alaska Native'
    | 'Asian Indian'
    | 'Chinese'
    | 'Filipino'
    | 'Japanese'
    | 'Korean'
    | 'Vietnamese'
    | 'Other Asian'
    | 'Native Hawaiian'
    | 'Guamanian or Chamorro'
    | 'Samoan'
    | 'Other Pacific Islander'
    | 'Other Race';
export type GenderIdentity =
    | 'Agender'
    | 'Androgyne'
    | 'Nonbinary or Genderqueer'
    | 'Transgender Male / Female to Male'
    | 'Male'
    | 'Transgender Female / Male to Female'
    | 'Female'
    | 'Questioning/unsure'
    | 'Choose not to disclose'
    | 'Other';

export type Ethnicities =
    | 'Not of Hispanic, Latino/a, or Spanish origin'
    | 'Mexican, Mexican American, Chicano/a'
    | 'Puerto Rican'
    | 'Cuban'
    | 'Another Hispanic, Latino/a, or Spanish origin'
    | 'Unknown';

export type PatientDTO = {
    patientType: PatientTypeOptionsDTO;
    firstName: string;
    lastName: string;
    preferredName?: string;
    sex: string;
    primaryLanguage?: LanguageDTO;
    birthDate: string;
    primaryDiagnosis?: string;
    sponsor: string;
    payor: string;
    ehr?: string;
    insurancePlan?: string;
    product: string;
    active?: boolean;
    info?: PatientInfoDTO;
    timeZone: string;
    podId: number;
    pod?: PodDTO;
    phaseOfCare?: string;
    discharge?: PatientDischargeResponseDTO;
    dischargeSource?: 'EHR' | 'Manual';
    hospitalInfo?: HospitalInfoDTO;
    genderIdentityOther?: string;
    sexualOrientation?: SexualOrientation;
    sexualOrientationOther?: string;
    races?: Races[];
    ethnicities?: Ethnicities[];
    genderIdentities?: GenderIdentity[];
    preferredSchedulingHours?: Schedule;
    medicalWasteManifestId1?: string;
    medicalWasteManifestId2?: string;
};
export type PatientDischargeResponseDTO = {
    destination?: DischargeDestinationDTO;
    /** Format: date */
    date?: string;
    comment?: string;
    dischargeBy?: string;
};
export type PatientInfoDTO = {
    id?: number;
    ehrMedicalRecordNumber?: string;
    emr?: string;
    primaryEHREncounterId?: string;
    secondaryEHREncounterId?: string;
    insuranceInfo?: PatientInsuranceInfoDTO;
    contactInfo?: PatientContactInfoDTO;
    homeAddress?: AddressDTO;
    nonVerbal?: boolean;
    additionalContacts?: PatientContactDTO[];
    local911?: string;
    local911Type?: 'EMS' | 'Police' | 'EMS & Police';
    additionalLocalEmergency?: string;
    additionalLocalEmergencyType?: 'Police' | 'Fire';
    admitDate: string;
    electricCompany?: ElectricCompanyDTO;
    homeAccessInfo?: string;
    referringFacilityName?: string;
    referringFacilityMrn?: string;
    serviceAreas?: string[];
};
export type PatientInsuranceInfoDTO = {
    insuranceCarrier?: string;
    insuranceMemberId?: string;
};
export type PatientPhoneNumberDTO = {
    phone?: string;
    preferred?: boolean;
};
export type BasePatientPrecautionDTO = {
    name?: string;
};
export type CommentDTO = {
    text?: string;
};
export type Extension = {
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
    id?: string;
    disallowExtensions?: boolean;
    url?: string;
    value?: Type;
    empty?: boolean;
    urlElement?: UriType;
    valueAsPrimitive?: IPrimitiveTypeObject;
    idElement?: StringType;
    extensionFirstRep?: Extension;
    idBase?: string;
    primitive?: boolean;
    resource?: boolean;
    booleanPrimitive?: boolean;
    dateTime?: boolean;
};
export type IBaseCoding = {
    system?: string;
    code?: string;
    display?: string;
    empty?: boolean;
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
};
export type IBaseMetaType = {
    tag?: IBaseCoding[];
    profile?: IPrimitiveTypeString[];
    security?: IBaseCoding[];
    /** Format: date-time */
    lastUpdated?: string;
    versionId?: string;
    empty?: boolean;
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
};
export type IBaseResource = {
    id?: IBaseResource;
    meta?: IBaseMetaType;
    idElement?: IIdType;
    structureFhirVersionEnum?: 'DSTU2' | 'DSTU2_HL7ORG' | 'DSTU2_1' | 'DSTU3' | 'R4' | 'R5';
    empty?: boolean;
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
};
export type IIdType = {
    empty?: boolean;
    value?: string;
    absolute?: boolean;
    local?: boolean;
    idPartAsLong?: number;
    idPart?: string;
    baseUrl?: string;
    versionIdPart?: string;
    versionIdPartAsLong?: number;
    idPartValid?: boolean;
    idPartValidLong?: boolean;
    versionIdPartValidLong?: boolean;
    resourceType?: string;
    valueAsString?: string;
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
};
export type IPrimitiveTypeObject = {
    value?: {[key: string]: unknown};
    valueAsString?: string;
    empty?: boolean;
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
};
export type IPrimitiveTypeString = {
    value?: string;
    valueAsString?: string;
    empty?: boolean;
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
};

export type PatientRecentStatisticDTO = {
    biometricIncidentCount?: number;
    /** Format: date-time */
    lastBiometricIncident?: string;
    bioAlertUnacknowledged?: boolean;
    techIncidentCount?: number;
    techAlertUnacknowledged?: boolean;
    /** Format: date-time */
    lastTechIncident?: string;
    newOrderCount?: number;
    orderCancellationCount?: number;
    schedulingRequestCount?: number;
    appointmentCancellationCount?: number;
    changeVendorCount?: number;
};
export type PatientDaySchedule = {
    startTime?: string;
    endTime?: string;
    enabled?: boolean;
};
export type PatientSchedule = {
    daySchedule?: {
        [key: string]: PatientDaySchedule;
    };
};
export type PatientResponseDTO = {
    firstName: string;
    lastName: string;
    preferredName?: string;
    sex: string;
    primaryLanguage?: {code: string; name: string};
    birthDate: string;
    primaryDiagnosis?: string;
    sponsor: string;
    payor: string;
    ehr?: string;
    insurancePlan?: string;
    product: string;
    active: boolean;
    info?: PatientInfoDTO;
    timeZone: string;
    patientType: PatientTypeOptionsDTO;
    podId: number;
    pod?: PodDTO;
    phaseOfCare?: string;
    discharge?: PatientDischargeResponseDTO;
    dischargeSource?: 'EHR' | 'Manual';
    hospitalInfo?: HospitalInfoDTO;
    id?: number;
    personnel?: PersonnelDTO;
    serviceCoordinator?: string;
    physician?: string;
    clinician?: string;
    missionControlId?: number;
    patientRecentStatistic?: PatientRecentStatisticDTO;
    photo?: MediaDTO;
    voided?: boolean;
    activePrecautions?: BasePatientPrecautionDTO[];
    sexualOrientation?: SexualOrientation;
    sexualOrientationOther?: string;
    races?: Races[];
    ethnicities?: Ethnicities[];
    genderIdentityOther?: string;
    genderIdentity?: GenderIdentity;
    genderIdentities?: GenderIdentity[];
    preferredSchedulingHours?: PatientSchedule;
    medicalWasteManifestId1?: string;
    medicalWasteManifestId2?: string;
    seniorServiceCoordinator?: string;
    acquisitionNurse?: string;
    chargeNurse?: string;
};
export type PersonnelDTO = {
    id?: number;
    email?: string;
    preferredEmail?: string;
    firstName?: string;
    lastName?: string;
    degree?: 'Bachelor';
    routingProfile?: string;
    clusters?: ClusterDTO[];
    sponsors?: string[];
    states?: StateResponseDTO[];
    statesSpecified?: boolean;
    archived?: boolean;
};
export type StringType = {
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
    id?: string;
    disallowExtensions?: boolean;
    valueNotNull?: string;
    primitive?: boolean;
    empty?: boolean;
    value?: string;
    valueAsString?: string;
    idElement?: StringType;
    extensionFirstRep?: Extension;
    idBase?: string;
    resource?: boolean;
    booleanPrimitive?: boolean;
    dateTime?: boolean;
};
export type Type = {
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
    id?: string;
    disallowExtensions?: boolean;
    empty?: boolean;
    idElement?: StringType;
    extensionFirstRep?: Extension;
    idBase?: string;
    primitive?: boolean;
    resource?: boolean;
    booleanPrimitive?: boolean;
    dateTime?: boolean;
};
export type UriType = {
    formatCommentsPre?: string[];
    formatCommentsPost?: string[];
    id?: string;
    disallowExtensions?: boolean;
    primitive?: boolean;
    empty?: boolean;
    value?: string;
    valueAsString?: string;
    idElement?: StringType;
    extensionFirstRep?: Extension;
    idBase?: string;
    resource?: boolean;
    booleanPrimitive?: boolean;
    dateTime?: boolean;
};

export type DeviceModelDTO = {
    identifier?: string;
    type?: string;
    manufacturer?: string;
    model?: string;
    description?: string;
    serialNumber?: string;
};
export type FaxNumberDTO = {
    label?: string;
    faxNumber: string;
};
export type UpdateMissionControlDTO = {
    name: string;
    fhirServerUrl?: string;
    faxNumbers?: FaxNumberDTO[];
    products?: number[];
    equipmentDepots?: number[];
    logoUrl?: string;
};
export type MissionControlResponseDTO = {
    id?: number;
    identifier?: string;
    name?: string;
    fullName?: string;
    training?: boolean;
    faxNumbers?: FaxNumberDTO[];
    products?: number[];
    equipmentDepots?: number[];
    logoUrl?: string;
};
export type PersonnelIdsDTO = {
    ids: number[];
};
export type UpdateClusterDTO = {
    name: string;
};
export type ClusterEscalationResponseDTO = {
    id?: number;
    name?: string;
    states?: StateResponseDTO[];
};
export type ClusterResponseDTO = {
    id?: number;
    name?: string;
    missionControlId?: number;
    pods?: PodDTO[];
    states?: StateResponseDTO[];
    escalations?: ClusterEscalationResponseDTO[];
    statesSpecified?: boolean;
    handleUnknownCallers: boolean;
};
export type StatusUpdate = {
    status?: EquipmentKitStatus;
    comment?: string;
};
export type UpdateEquipmentKitRequestDTO = {
    name?: string;
    equipmentKitSpecificationId?: string;
    comment?: string;
    homeLocationId?: number;
    currentLocationId?: number;
    statusUpdate?: StatusUpdate;
};
export type DeviceModel = {
    identifier?: string;
    type?: string;
    manufacturer?: string;
    model?: string;
    description?: string;
    enabled?: boolean;
};
export type DeviceResponseDTO = {
    id?: number;
    deviceModel?: DeviceModel;
    deviceModelId?: string;
    type?: string;
    manufacturer?: string;
    model?: string;
    endPoint?: string;
    serialNumber?: string;
    assetTag?: string;
    status?: DeviceStatus;
    /** Format: date */
    dateAcquired?: string;
    comment?: string;
    homeLocationId?: number;
    homeLocationName?: string;
    equipmentKitId?: number;
    equipmentKitName?: string;
    deviceSpecific?: {[key: string]: string};
};
export type EquipmentKitResponseDTO = {
    id?: number;
    name?: string;
    equipmentKitSpecificationId?: string;
    equipmentKitType?: string;
    status?: string;
    comment?: string;
    patientId?: number;
    homeLocationId?: number;
    homeLocationName?: string;
    currentLocationId?: number;
    currentLocationName?: string;
    /** Format: date-time */
    patientAssignedDateTime?: string;
    /** Format: date-time */
    patientUnassignedDateTime?: string;
    postInstallationChecklistId?: number;
    phoneNumbers?: PhoneResponseDTO[];
    devices?: DeviceResponseDTO[];
    demo?: boolean;
};
export type PhoneResponseDTO = {
    number?: string;
    phoneType?: string;
};
export type CreateOrUpdateEquipmentDepotRequestDTO = {
    name?: string;
    address?: EmbeddableAddressDTO;
    type?: 'HOME_LOCATION' | 'NOT_SPECIFIED';
};
export type EmbeddableAddressDTO = {
    address1?: string;
    address2?: string;
    city?: string;
    district?: string;
    state?: string;
    zipCode?: string;
    country?: string;
};
export type EquipmentDepotResponseDTO = {
    id?: number;
    name?: string;
    address?: EmbeddableAddressDTO;
    type?: 'HOME_LOCATION' | 'NOT_SPECIFIED';
};
export type CreateOrUpdateDeviceRequestDTO = {
    deviceModelId?: string;
    serialNumber?: string;
    assetTag?: string;
    status?: DeviceStatus;
    homeLocationId: number;
    /** Format: date */
    dateAcquired?: string;
    comment?: string;
    deviceSpecific?: {[key: string]: string};
};
export type InstancePropsDTO = {
    code?: string;
    label?: string;
    id?: number;
};
export type MediaStatusDTO = {
    label?: string;
};
export type MediaStatusResponseDTO = {
    label?: string;
    id?: number;
};
export type ConfigurationDTO = {
    name?: string;
    stringValue?: string;
    intValue?: number;
};
export type ChangeVendorRequestDTO = {
    selectedPartner?: SelectedVendorRequestDTO;
    createServiceRequest?: boolean;
    handOffVendorId: number;
};
export type SelectedVendorRequestDTO = {
    actorCode: string;
    siteCode: string;
    vendorCode: string;
};
export type OrderIgnoreRequestDTO = {
    reason: 'Order placed in error' | 'Order already entered manually' | 'Order not relevant to Cesia' | 'Other';
    comment?: string;
};
export type ActivityBundle = {
    activityRequestList?: ActivityRequest[];
};
export type ActivityRequest = {
    code?: string;
    label?: string;
};
export type AddFlexibleOrderActivitiesRequestDTO = {
    bundles: ActivityBundle[];
    /**
     * Format: date
     * @example 2019-10-24
     */
    activityDate: string;
    recurrence?: number;
};
export type ResetOrderSpecificationRequest = {
    resetConfirmationCode?: string;
};
export type AppointmentNoteDTO = {
    text?: string;
};
export type UpdateInHomeAppointmentRequestDTO = {
    /**
     * Format: date
     * @example 2019-10-24
     */
    appointmentStartDate?: string;
    /** @example 10:00 */
    appointmentStartTime?: string;
    /** @example 10:00 */
    appointmentEndTime?: string;
    timeRangeAppointment?: boolean;
    /** @example 2020-12-03 10:15 */
    checkInTime?: string;
    /** @example 2020-12-03 10:15 */
    checkOutTime?: string;
    actorFirstName?: string;
    actorLastName?: string;
    activityIds?: number[];
    duration?: string;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
    manualDuration?: boolean;
    shouldNotCreateServiceRequest?: boolean;
    instructions?: string;
    wheneverFeasible?: boolean;
    clinicalWindowStart?: string;
    clinicalWindowEnd?: string;
    schedulingWindowStart?: string;
    schedulingWindowEnd?: string;
};
export type IdResponseDTO = {
    id?: number;
};

export type AppointmentAutosaveResponseDTO = IdResponseDTO & {
    clinicallyPreferredTime?: string;
};

export type IdResponseWithNotificationIdDTO = {
    id?: number;
    notificationId?: string;
};
export type UpdateInHomeAppointmentMissedCheckInOutDTO = {
    /** @example 2020-12-03 10:15 */
    checkInTime?: string;
    /** @example 2020-12-03 10:15 */
    checkOutTime?: string;
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
    doneTimingOption?: 'ON_TIME' | 'LATE';
    instructions?: string;
};
export type InHomeAppointmentMissedCheckInOutDTO = {
    /** @example 2020-12-03 10:15 */
    actualStartTime?: string;
    /** @example 2020-12-03 10:15 */
    actualEndTime?: string;
    comment?: string;
    createServiceRequest?: boolean;
    reportOption: MissedCheckInOutReportOption;
    doneTimingOption?: 'ON_TIME' | 'LATE';
    followupActivities?: {
        [key: string]: ActivityCheckoutAction;
    };
};
export type ActivityDocumentDetailsRequestDTO = {
    activityId: number;
    comments?: string;
    /** @example 2020-06-14 10:15 */
    docReceived?: string;
    docRequired?: boolean;
    docNotReceived?: boolean;
};
export type DocumentInHomeAppointmentRequestDTO = {
    activities?: ActivityDocumentDetailsRequestDTO[];
    comments?: string;
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
};
export type InHomeAppointmentDocumentationStatusResponseDto = {
    appointmentId?: number;
    documented?: boolean;
};
export type ConfirmInHomeAppointmentRequestDTO = {
    schedule?: InHomeAppointmentScheduleDTO;
    activityIds?: number[];
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
};
export type InHomeAppointmentScheduleDTO = {
    /** @example 10:00 */
    startTime?: string;
    /** @example 10:00 */
    endTime?: string;
    timeRangeAppointment?: boolean;
    duration?: string;
    manualDuration?: boolean;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    /**
     * Format: date
     * @example 2019-10-24
     */
    startDate?: string;
};
export type InHomeAppointmentCheckoutDTO = {
    checklist: MultiTypeChecklist;
    serviceProviderRating?: number;
    followupActivities?: {
        [key: string]: ActivityCheckoutAction;
    };
    /** @example 2020-12-03 10:15 */
    checkoutTime: string;
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
    actorFirstName?: string;
    actorLastName?: string;
    comment?: string;
};
export type MultiTypeChecklist = {
    type?: 'CHECKBOX' | 'YES_NO_NA';
    items?: MultiTypeOptionItem[];
};
export type MultiTypeOptionItem = {
    label?: string;
    answer?: 'YES' | 'NO' | 'N_A' | 'CHECKED' | 'UNCHECKED';
    /** @deprecated */
    checked?: boolean;
};
export type ActivityCheckinChecklist = {
    type?: 'CHECKBOX' | 'YES_NO_NA';
    items?: MultiTypeOptionItem[];
    activityId?: number;
};
export type CheckInDTO = {
    /** @example 2020-12-03 10:15 */
    startTime: string;
    checkinActivities?: ActivityCheckinChecklist[];
    comment?: string;
    actorFirstName?: string;
    actorLastName?: string;
    rating?: number;
    duration?: string;
    manualDuration?: boolean;
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
    selfCheckOutConfiguration?: string;
};
export type AppointmentChangeVendorRequestDTO = {
    performer?: SelectedVendorRequestDTO;
    createServiceRequest?: boolean;
    note?: AppointmentNoteDTO;
    reason: ChangingPartnerReasonRequestDTO;
};
export type AppointmentCancellationRequestDTO = {
    reason:
        | 'Clinical Order Cancelled'
        | 'Service Provider Cancelled Appointment'
        | 'Patient Refused Appointment/ Patient Unavailable'
        | 'Post Scheduling Change - New Requirements'
        | 'Service Provider No Show'
        | 'Scheduled in Error';
    comment?: string;
    createServiceRequest?: boolean;
    transformIntoSchedulingRequest?: boolean;
    note?: AppointmentNoteDTO;
};
export type UnavailableServiceProviderRequestDTO = {
    reason: UnavailableServiceProvider;
    createServiceRequest?: boolean;
    transformIntoSchedulingRequest?: boolean;
    note?: AppointmentNoteDTO;
};
export type AddActivitiesRequestDTO = {
    activityIds?: number[];
};
export type ChecklistConfigurationDTO = {
    type?: 'CHECKBOX' | 'YES_NO_NA';
    items?: Item[];
};
export type Item = {
    label?: string;
    answer?: 'YES' | 'NO' | 'N_A' | 'CHECKED' | 'UNCHECKED';
};
export type UnplannedActivityDTO = {
    id: number;
    activityName?: string;
    activityCode?: string;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    nurseRequired?: boolean;
    orderId?: number;
    orderName?: string;
    ehrOrderId?: string;
    providerFirstName?: string;
    providerLastName?: string;
    clinicalInfo?: string;
    comment?: string;
    nurseCheckListConfiguration?: ChecklistConfigurationDTO;
    providerCheckListConfiguration?: ChecklistConfigurationDTO;
};
export type AddServiceRequestActivitiesRequestDTO = {
    activityIds: number[];
    appointmentId: number;
    duration?: string;
    manualDuration?: boolean;
};
export type CreateMultiDayMultipleActivitiesInHomeAppointmentRequestDTO = {
    performer: SelectedVendorRequestDTO;
    activityIds: number[];
    showOnPatient?: boolean;
    allDaysSchedule?: InHomeAppointmentTimeScheduleDTO;
    handoffId?: number;
    individualSchedules?: InHomeAppointmentScheduleDTO[];
    note?: AppointmentNoteDTO;
};
export type CreateOrUpdateInHomeAppointmentsRequestDTO = {
    handoffId?: number;
    createInHomeAppointments?: CreateMultiDayMultipleActivitiesInHomeAppointmentRequestDTO;
    updateInHomeAppointments?: AddServiceRequestActivitiesRequestDTO[];
};
export type InHomeAppointmentTimeScheduleDTO = {
    /** @example 10:00 */
    startTime?: string;
    /** @example 10:00 */
    endTime?: string;
    timeRangeAppointment?: boolean;
    duration?: string;
    manualDuration?: boolean;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
};
export type AddActivitiesFromHandoffRequestDTO = {
    handoffId?: number;
    requests?: AddServiceRequestActivitiesRequestDTO[];
};
export type Attendees =
    | 'Mission Control Nurse'
    | 'Mission Control NP'
    | 'Mission Control PA'
    | 'Mission Control Physician'
    | 'Nurse Practitioner'
    | 'Physician';
export type UpdateAppointmentRequestDTO = {
    title: string;
    description?: string;
    generalAppointmentType?: string;
    /**
     * Format: date
     * @example 2019-10-24
     */
    startDate: string;
    /** @example 10:00 */
    startTime?: string;
    /** @example 11:00 */
    endTime?: string;
    /**
     * Format: date
     * @example 2019-10-28
     */
    endOfRecurrence?: string;
    recurrence: 'Once' | 'Daily' | 'Every Other Day' | 'Weekly';
    showOnPatient?: boolean;
    timeRangeAppointment: boolean;
    state: 'Scheduled' | 'Started' | 'Complete' | 'Cancelled' | 'Removed';
    attendees?: Attendees[];
    updateConcrete: boolean;
};

export type NotificationDTO = {
    id?: number;
    target?: string;
    notificationType?: 'EMAIL';
};
export type ThresholdDTO = {
    id?: number;
    triggerValue?: number;
    severityLevel?: 'ONE' | 'TWO' | 'THREE' | 'Urgent' | 'Moderate' | 'Mild';
};
export type AssignmentNurseDTO = {
    id?: number;
};
export type AssignmentPersonnelDTO = {
    unassign?: boolean;
    id?: number;
};
export type MultiAssignmentCreateDTO = {
    patientIds: number[];
    assignedNurse?: AssignmentNurseDTO;
    assignedServiceCoordinator?: AssignmentPersonnelDTO;
    assignedPhysician?: AssignmentPersonnelDTO;
    assignedClinician?: AssignmentPersonnelDTO;
};
export type PersonnelCreateDTO = {
    email: string;
    preferredEmail?: string;
    firstName: string;
    lastName: string;
    roles: string[];
    routingProfile?: string;
};
export type VendorCalendarRequestDTO = {
    sites: string[];
    actors?: string[];
    states?: ('Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation')[];
};
export type VendorAppointmentCalendarItemDTO = {
    generalAppointmentType?:
        | 'Video Visit'
        | 'Phone Call'
        | 'Vital Signs'
        | 'Patient Reminder/Message'
        | 'Facility Visit'
        | 'Other';
    state?: 'Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation';
    status?: string;
    startTime?: string;
    startDateTime?: string;
    endTime?: string;
    endDateTime?: string;
    startDate?: string;
    timeCalculated?: boolean;
    timeZone?: string;
    id?: number;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    expectedDuration?: string;
    siteCode?: string;
    actorName?: string;
    actor?: string;
};
export type VendorCalendarItemDTO = {
    siteCode?: string;
    vendorName?: string;
    siteName?: string;
};
export type VendorCalendarResponseDTO = {
    vendors?: VendorCalendarItemDTO[];
    items?: VendorAppointmentCalendarItemDTO[];
};
export type CreatedResponseCodeDTO = {
    code?: string;
};
export type VendorNotAvailableRequestDTO = {
    activityIds?: number[];
    reason: UnavailableServiceProvider;
};
export type VendorAvailabilitySearchRequestDTO = {
    payor?: string;
    sponsor?: string;
    zipCode?: string;
    city?: string;
    state?: string;
    serviceAreas?: number[];
    activities?: string[];
};
export type ActivitySearchResponseDTO = {
    name?: string;
    code?: string;
    priority?: number;
};
export type ActorSearchResponseDTO = {
    name?: string;
    code?: string;
    priority?: number;
    contactInfo?: ContactInfoResponseDTO;
    selfCheckInOption?: string;
    selfCheckOutOption?: string;
    serviceNowIntegrationEnabled?: boolean;
};
export type ContactInfoResponseDTO = {
    instructions?: string[];
    address?: Address;
    documents?: SupportingDocumentReference[];
    contacts?: Contact[];
    serviceDeliveryHours?: {
        [key: string]: DaySchedule;
    };
    schedulingHours?: {[key: string]: DaySchedule};
};
export type EligibleVendorActorsSearchResponseDTO = {
    siteCode?: string;
    siteName?: string;
    vendorCode?: string;
    vendorName?: string;
    actor?: ActorSearchResponseDTO;
    activities?: ActivitySearchResponseDTO[];
    serviceNowIntegrationEnabled?: boolean;
};
export type PatientVendorSearchRequestDTO = {
    patientId?: number;
    activities?: string[];
    showOnlyFullCoverageVendors?: boolean;
};
export type EligibleVendorActorsSearchRequestDTO = {
    townName?: string;
    townState?: string;
    zipCode?: string;
    payorId?: number;
    sponsorId?: number;
    activityCodes?: string[];
};
export type SPIRequestMetaDTO = {
    handOffVendorId: number;
    integrationAction: 'SEND_REFERRAL_DOCUMENTATION' | 'SEND_APPOINTMENT_REQUEST';
};
export type SPIStatusResponseDTO = {
    status?: 'IN_PROGRESS' | 'DONE' | 'FAILED';
    /** Format: date-time */
    timeStamp?: string;
};
export type PatientPrecautionCreateDTO = {
    patientId: number;
    startDate: string;
    endDate?: string;
    precautionIds: number[];
};
export type CreateStandardTaskDTO = {
    patientId: number;
    templateId: number;
    /** @example 2020-12-03 10:15 */
    dueDate: string;
    scratchPad?: string;
    shortName?: string;
};
export type AttendingPhysicianAssignment = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    patient?: Patient;
    personnel?: Personnel;
    type?: 'NURSE' | 'SERVICE_COORDINATOR' | 'ATTENDING_PHYSICIAN' | 'RESPONSIBLE_CLINICIAN';
};
export type PrecautionChangeDetail = {
    label?: 'Added Precaution' | 'End Date' | 'Discontinued Precaution';
    change?: ChangeObject;
};
export type ChangeObject = {
    oldValue?: string;
    newValue?: string;
};
export type Cluster = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    missionControl?: MissionControl;
    name?: string;
    pods?: Pod[];
    states?: string[];
    statesSpecified?: boolean;
    escalations?: Cluster[];
    personnels?: Personnel[];
};
export type Diagnosis = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    sequenceId?: number;
    codingMethod?: string;
    codingSystem?: string;
    identifier?: string;
    text?: string;
    description?: string;
    /** Format: date-time */
    dateTime?: string;
    type?: 'ADMITTING' | 'FINAL' | 'WORKING';
    patient?: Patient;
};
export type DischargeDestination = {
    code?: string;
    label?: string;
    availableForManual?: boolean;
    ignoreFromEhr?: boolean;
};
export type Ehr = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    code?: string;
    label?: string;
};
export type ElectricCompany = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    patient?: Patient;
    companyName?: string;
    phone?: string;
    extension?: number;
    comment?: string;
};
export type EmbeddableAddress = {
    address1?: string;
    address2?: string;
    city?: string;
    district?: string;
    state?: string;
    zipCode?: string;
    country?: string;
};
export type EquipmentDepot = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    name?: string;
    address?: EmbeddableAddress;
    type?: 'HOME_LOCATION' | 'NOT_SPECIFIED';
    personnels?: Personnel[];
};
export type FaxNumber = {
    label?: string;
    faxNumber?: string;
};
export type Guarantor = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    number?: string;
    homePhone?: string;
    workPhone?: string;
    /** Format: date */
    dateOfBirth?: string;
    sex?: string;
    type?: string;
    relationship?: string;
    insuredFirstName?: string;
    insuredLastName?: string;
    spouseFirstName?: string;
    spouseLastName?: string;
    address?: Address;
    patient?: Patient;
};
export type MissionControl = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    identifier?: string;
    name?: string;
    training?: boolean;
    fhirServerUrl?: string;
    faxNumbers?: FaxNumber[];
    personnels?: Personnel[];
    products?: Product[];
    equipmentDepots?: EquipmentDepot[];
    defaultDuringMigration?: boolean;
    patients?: Patient[];
    logoUrl?: string;
};
export type NurseAssignment = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    patient?: Patient;
    personnel?: Personnel;
    type?: 'NURSE' | 'SERVICE_COORDINATOR' | 'ATTENDING_PHYSICIAN' | 'RESPONSIBLE_CLINICIAN';
};
export type Patient = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    /** Format: date */
    birthDate?: string;
    gender?: 'Male' | 'Female' | 'Other' | 'Unknown';
    status?: 'ACTIVE' | 'INACTIVE';
    nurseAssignment?: NurseAssignment;
    serviceCoordinatorAssignment?: ServiceCoordinatorAssignment;
    attendingPhysicianAssignment?: AttendingPhysicianAssignment;
    responsibleClinicianAssignment?: ResponsibleClinicianAssignment;
    shiftAssignments?: ShiftAssignment[];
    genderIdentity?: string;
    primaryLanguage?: string;
    genderIdentityOther?: string;
    primaryDiagnosis?: string;
    sponsor?: Sponsor;
    payor?: Payor;
    insurancePlan?: string;
    product?: Product;
    ehr?: Ehr;
    emr?: string;
    primaryEHREncounterId?: string;
    secondaryEHREncounterId?: string;
    insuranceMemberId?: string;
    mobilePhone?: Phone;
    homePhone?: Phone;
    homeAddress?: Address;
    additionalContacts?: PatientContact[];
    diagnoses?: Diagnosis[];
    guarantor?: Guarantor;
    local911?: string;
    local911Type?: 'EMS' | 'Police' | 'EMS & Police';
    additionalLocalEmergency?: string;
    additionalLocalEmergencyType?: 'Police' | 'Fire';
    pharmacy1?: Pharmacy;
    pharmacy2?: Pharmacy;
    fhirId?: string;
    ehrMedicalRecordNumber?: string;
    /** Format: date */
    admitDate?: string;
    timeZone?: string;
    patientType?: PatientTypeOptionsDTO;
    phaseOfCare?: string;
    missionControl?: MissionControl;
    pod?: Pod;
    patientRecentStatistic?: PatientRecentStatistic;
    photoId?: string;
    discharge?: PatientDischarge;
    electricCompany?: ElectricCompany;
    voided?: boolean;
    voidedDetails?: VoidedDetails;
    hospitalInfo?: PatientHospitalInfo;
    precautions?: PatientPrecaution[];
    active?: boolean;
    fullName?: string;
    activePrecautions?: PatientPrecaution[];
    timeZoneInstance?: {
        displayName?: string;
        id?: string;
        dstsavings?: number;
        rawOffset?: number;
    };
    /** Format: date */
    todaysDate?: string;
    age?: number;
};
export type PatientContact = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    patient?: Patient;
    firstName?: string;
    lastName?: string;
    relationship?: 'EMERGENCY_CONTACT' | 'CAREGIVER' | 'SPOUSE';
    phoneNumber?: Phone;
};
export type PatientDischarge = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    destination?: DischargeDestination;
    patient?: Patient;
    /** Format: date */
    date?: string;
    comment?: string;
    dischargeBy?: string;
    source?: 'EHR' | 'Manual';
};
export type PatientHospitalInfo = {
    patientId?: number;
    hospitalDays?: number;
    lengthOfStay?: number;
    lastBiometricReading?: number;
};
export type PatientPrecaution = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    /** Format: date */
    startDate?: string;
    /** Format: date */
    endDate?: string;
    precaution?: Precaution;
    patient?: Patient;
    personnel?: Personnel;
    voided?: boolean;
    discontinued?: boolean;
    histories?: PatientPrecautionHistory[];
    active?: boolean;
    outdated?: boolean;
};
export type PatientPrecautionHistoryChangeDetails = {
    changes?: PrecautionChangeDetail[];
    reason?: string;
};
export type PatientPrecautionHistory = {
    id?: number;
    /** Format: date-time */
    eventDateTime?: string;
    personnel?: Personnel;
    personnelFullName?: string;
    event?:
        | 'Added Precaution'
        | 'Edited Precaution'
        | 'Voided Precaution'
        | 'Inactivated Patient'
        | 'Discontinued Precaution';
    changeDetails?: PatientPrecautionHistoryChangeDetails;
    patientPrecaution?: PatientPrecaution;
};

export type PatientRecentStatistic = {
    id?: number;
    patient?: Patient;
    biometricIncidentCount?: number;
    bioAlertUnacknowledged?: boolean;
    /** Format: date-time */
    lastBiometricIncident?: string;
    techIncidentCount?: number;
    techAlertUnacknowledged?: boolean;
    /** Format: date-time */
    lastTechIncident?: string;
    newOrderCount?: number;
    orderCancellationCount?: number;
    schedulingRequestCount?: number;
    appointmentCancellationCount?: number;
    changeVendorCount?: number;
};
export type Payor = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    code?: string;
    label?: string;
};
export type Personnel = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    email?: string;
    preferredEmail?: string;
    degree?: 'Bachelor';
    missionControls?: MissionControl[];
    equipmentDepots?: EquipmentDepot[];
    clusters?: Cluster[];
    firstName?: string;
    lastName?: string;
    nurseAssignments?: NurseAssignment[];
    shiftAssignments?: ShiftAssignment[];
    sponsors?: Sponsor[];
    licenseStatesSpecified?: boolean;
    licenseStates?: string[];
    routingProfile?: string;
    archived?: boolean;
    archivedWithRoles?: string[];
    alternateUsername?: string;
    fullName?: string;
    assignedActivePatientIds?: number[];
    specifiedStates?: string[];
    assignedActivePatients?: Patient[];
    assignedPatients?: Patient[];
};
export type Pharmacy = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    name?: string;
    address?: Address;
    phone?: Phone;
    fax?: Phone;
    notes?: string;
};
export type Phone = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    phone?: string;
    preferred?: boolean;
};
export type Pod = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    name?: string;
    clusterId?: number;
    cluster?: Cluster;
    active?: boolean;
};
export type Precaution = {
    id?: number;
    name?: string;
    ehr?: Ehr;
    voided?: boolean;
    default?: boolean;
};
export type Product = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    code?: string;
    label?: string;
};

export type ResponsibleClinicianAssignment = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    patient?: Patient;
    personnel?: Personnel;
    type?: 'NURSE' | 'SERVICE_COORDINATOR' | 'ATTENDING_PHYSICIAN' | 'RESPONSIBLE_CLINICIAN';
};
export type ServiceCoordinatorAssignment = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    patient?: Patient;
    personnel?: Personnel;
    type?: 'NURSE' | 'SERVICE_COORDINATOR' | 'ATTENDING_PHYSICIAN' | 'RESPONSIBLE_CLINICIAN';
};
export type ShiftAssignment = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    patient?: Patient;
    personnel?: Personnel;
    type?: 'NURSE' | 'SERVICE_COORDINATOR' | 'ATTENDING_PHYSICIAN' | 'RESPONSIBLE_CLINICIAN';
};
export type Sponsor = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    code?: string;
    label?: string;
};
export type VoidedDetails = {
    id?: number;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    deleted?: boolean;
    patient?: Patient;
    reason?: 'Void Demo Patient' | 'Patient Not Admitted';
    comment?: string;
    /** Format: date-time */
    date?: string;
    voidedBy?: string;
};
export type ZoomMeetingParticipantDTO = {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    isPatient?: boolean;
    user_id?: string;
    user_name?: string;
    /** Format: date-time */
    join_time?: string;
    /** Format: date-time */
    leave_time?: string;
};
export type ZoomMeetingResponseDTO = {
    joinUrl?: string;
    startUrl?: string;
    meetingId?: number;
    password?: string;
    patientId?: number;
    patientFullName?: string;
    patientSponsor?: string;
    assignedNurseFirstName?: string;
    assignedNurseLastName?: string;
    status?: string;
    /** Format: date-time */
    createDate?: string;
    /** Format: date-time */
    startTime?: string;
    /** Format: date-time */
    endTime?: string;
    duration?: number;
    participantsCount?: number;
    name?: string;
    initiatedByPatient?: boolean;
    participants?: ZoomMeetingParticipantDTO[];
    aloneAfterJoinTimeout?: number;
    aloneAfterLeaveTimeout?: number;
};
export type VisitLogRequestDTO = {
    initialPoint?: string;
};
export type IdsRequestParamDTO = {
    ids?: number[];
};
export type PersonnelFileRequestDto = {
    roleIds: string[];
    routingProfile?: string;
    clusterId?: number;
    sponsorIds?: string[];
};
export type PersonnelFileResponseStatus =
    | 'SUCCESSFULLY_ADDED'
    | 'USER_ALREADY_EXISTS'
    | 'INVALID_FIRST_NAME'
    | 'INVALID_LAST_NAME'
    | 'INVALID_EMAIL'
    | 'INVALID_USER_ID'
    | 'INVALID_PREFERRED_EMAIL'
    | 'INVALID_STATES'
    | 'NO_STATE_LICENSE';
export type PersonnelFileResponseDto = {
    id?: number;
    name: string;
    userId?: string;
    email: string;
    preferredEmail?: string;
    roles?: string[];
    routingProfile?: string;
    cluster?: string;
    sponsors?: string[];
    states?: string[];
    statuses?: PersonnelFileResponseStatus[];
};
export type PatientScratchpadCreateDTO = {
    text?: string;
    patientId?: number;
};
export type PatientScratchpadDTO = {
    id?: number;
    text?: string;
    personnelFullname?: string;
    /** Format: date-time */
    dateTime?: string;
};
export type CreateMissionControlDTO = {
    name: string;
    identifier: string;
    training: boolean;
    fhirServerUrl?: string;
    faxNumbers?: FaxNumberDTO[];
    products?: number[];
    equipmentDepots?: number[];
    logoUrl?: string;
};
export type CreateClusterDTO = {
    name: string;
    /** @deprecated */
    identifier?: string;
    missionControlId?: number;
};
export type ContactAttributesDTO = {
    initialContactId?: string;
    sourceAgentEmail?: string;
    destinationAgentEmail?: string;
};
export type CreateEquipmentKitRequestDTO = {
    name?: string;
    equipmentKitSpecificationId?: string;
    comment?: string;
    homeLocationId?: number;
    currentLocationId?: number;
    statusUpdate?: StatusUpdate;
    demo?: boolean;
};
export type CreateOrderRequestDTO = {
    category?: string;
    orderNameCode?: string;
    clinicalInfo?: string;
    comment?: string;
    orderName?: string;
    subcategory?: string;
    orderType?: 'HOME' | 'GENERAL' | 'BOTH';
    ehrOrderNumber?: string;
    patientId: number;
    frequency: Frequency;
    originalFrequencyText?: string;
    durationInDays?: number;
    /** Format: date */
    startDate: string;
    /** Format: date */
    endDate?: string;
    providerFirstName?: string;
    providerLastName?: string;
    certifiedByFirstName?: string;
    certifiedByLastName?: string;
    activityBundleId?: string;
    flexibleScheduling?: boolean;
    openEnded?: boolean;
    primary?: boolean;
    inDayOccurrenceLabels?: {[key: string]: string};
    taskTemplateIds?: number[];
    needAcknowledge?: boolean;
};
export type ActionResponseDTO = {
    scheduledDate?: string;
    scheduledTime?: string;
    dueDate?: string;
    orderingNumber?: number;
    actionType?: FollowupAction;
    status?: 'Not Started' | 'In Progress' | 'Overdue' | 'Skipped' | 'Done';
    displayName?: string;
};
export type ActivityCompletedDetailsDTO = {
    status?: string;
    personnelName?: string;
    /** Format: date-time */
    date?: string;
    comment?: string;
};
export type ActivityResponseDTO = {
    id?: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    defaultForLateExecution?: number;
    completedDetails?: ActivityCompletedDetailsDTO;
    specification?: ActivitySpecification;
    action?: ActionResponseDTO;
    isInPreCheckInState?: boolean;
    inHomeAppointmentId?: number;
    inHomeAppointmentStatus?: InHomeAppointmentStatus;
    inHomeAppointmentStartDateTime?: string;
    inHomeAppointmentArrivalWindow?: string;
    activeHandOffId?: number;
    handOffType?: 'One day' | 'One action' | 'Mixed';
    scheduleDate?: string;
    scheduledTime?: string;
    cardinality?: number;
    required?: boolean;
    hasRequiredComments?: boolean;
    allowUndoConfirm?: boolean;
    allowUndoCheckIn?: boolean;
    allowUndoCheckOut?: boolean;
    allowEdit?: boolean;
    selfCheckInConfiguration?:
        | 'Never Allow'
        | 'Allow, confirmation call is always required'
        | 'Allow, confirmation call is not required';
    selfCheckOutConfiguration?:
        | 'Never Allow'
        | 'Allow, confirmation call is always required'
        | 'Allow, confirmation call is not required';
    isTBD?: boolean;
    blockingInfo?: BlockedActivityDetailsDTO;
};
export type ActivitySpecification = {
    code?: string;
    actionGroupType?: string;
    name?: string;
    showOnPatient?: boolean;
    expectedDuration?: string;
    expectedDurationSecondary?: string;
    defaultAppointmentArrivalWindow?: string;
    maxEarlyArrival?: string;
    checkInTypeCode?: string;
    checkOutTypeCode?: string;
    serviceProviderCheckInTypeCode?: string;
    serviceProviderCheckOutTypeCode?: string;
    supplyChainCategory?: string;
    nurseRequired?: boolean;
};
export type OrderHistoryDTO = {
    status?: string;
    createdTasksNames?: string[];
    /** Format: date-time */
    timestamp?: string;
    cesiaUser?: string;
};
export type OrderWithActivitiesResponseDTO = {
    id?: number;
    orderType?: 'HOME' | 'GENERAL' | 'BOTH';
    category?: string;
    interfaceCategory?: string;
    source?: string;
    orderNameCode?: string;
    orderName?: string;
    clinicalInfo?: string;
    comment?: string;
    patientId?: number;
    patientTimeZone?: string;
    frequency?: Frequency;
    inDayOccurrences?: number;
    unknownFrequency?: string;
    originalFrequencyText?: string;
    /** Format: date */
    startDate?: string;
    /** Format: date-time */
    startDateTime?: string;
    /** Format: date-time */
    endDateTime?: string;
    /** Format: date */
    endDate?: string;
    providerFirstName?: string;
    providerLastName?: string;
    certifiedByFirstName?: string;
    certifiedByLastName?: string;
    acknowledged?: boolean;
    /** Format: date-time */
    acknowledgedDateTime?: string;
    acknowledgedBy?: string;
    canceledBy?: string;
    /** Format: date-time */
    acknowledgeCancellation?: string;
    /** Format: date-time */
    cancelEffectiveDateTime?: string;
    state?: OrderState;
    activities?: ActivityResponseDTO[];
    activityDays?: string[];
    subcategory?: string;
    strength?: string;
    strengthUnits?: string;
    route?: string;
    attributeValue?: string;
    response?: string;
    history?: OrderHistoryDTO[];
    /** Format: date */
    upcomingActionDate?: string;
    flexibleScheduling?: boolean;
    addingActivitiesAllowed?: boolean;
    openEnded?: boolean;
    defaultFlexibleScheduling?: boolean;
    primary?: boolean;
    ehrEncounterId?: string;
};
export type HandOffCreateDTO = {
    patientId: number;
    type?: 'One day' | 'One action' | 'Mixed';
    urgency?: HandOffUrgency;
    instruction?: string;
    vendors: HandOffVendor[];
    activities: number[];
};
export type HandOffVendor = {
    id?: number;
    vendorCode?: string;
    vendorName?: string;
    siteCode?: string;
    actorCode?: string;
    sequence?: number;
    notAvailable?: boolean;
    notAvailableReason?: UnavailableServiceProvider;
    subStatuses?: HandOffVendorSubStatus[];
    spiDetails?: HandOffVendorSPIDetails[];
};
export type HandOffVendorSPIDetails = {
    id?: number;
    handOffVendor?: HandOffVendor;
    integrationAction?: 'SEND_REFERRAL_DOCUMENTATION';
    status?: 'IN_PROGRESS' | 'DONE' | 'FAILED';
    /** Format: date-time */
    statusUpdateDate?: string;
};
export type HandOffVendorSubStatus = {
    id?: number;
    personnel?: Personnel;
    /** Format: date-time */
    dateTime?: string;
    substatus?: string;
};

export type ChangingPartnerReasonRequestDTO = ChangingPartnerReasonDTO;

export type ChangeVendorHandOffCreateDTO = {
    instruction?: string;
    vendors: HandOffVendor[];
    appointmentId?: number;
    reason: ChangingPartnerReasonRequestDTO;
};
export type CreateInHomeAppointmentRequestDTO = {
    schedule?: InHomeAppointmentScheduleDTO;
    performer?: SelectedVendorRequestDTO;
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
    handoffId?: number;
    activityIds: number[];
};
export type ActivityCheckoutAction = 'COMPLETED' | 'NEEDS_TO_BE_RESCHEDULED' | 'DO_NOT_RESCHEDULE';
export type SelfCheckOutReviewActivityRequestDTO = {
    id: number;
    confirmed?: boolean;
    providerChecklist?: MultiTypeChecklist;
    nurseChecklist?: MultiTypeChecklist;
    refuseReasonId?: number;
    refuseReasonComment?: string;
    activityCheckoutAction: ActivityCheckoutAction;
};
export type SelfCheckOutReviewRequestDTO = {
    actorFirstName: string;
    actorLastName: string;
    rating?: number;
    note?: AppointmentNoteDTO;
    comment?: string;
    voidComment?: string;
    checkOutTime: string;
    activities: SelfCheckOutReviewActivityRequestDTO[];
};
export type ReviewActivityRequestDTO = {
    id: number;
    confirmed?: boolean;
    providerChecklist?: MultiTypeChecklist;
    nurseChecklist?: MultiTypeChecklist;
    refuseReasonId?: number;
    refuseReasonComment?: string;
};
export type SelfCheckInReviewRequestDTO = {
    actorFirstName: string;
    actorLastName: string;
    rating?: number;
    note?: AppointmentNoteDTO;
    comment?: string;
    voidComment?: string;
    /** @example 2020-12-03 10:15 */
    actualArrivalTime: string;
    activities: ReviewActivityRequestDTO[];
    selfCheckOutConfiguration: string;
};
export type CreateMultiDayInHomeAppointmentRequestDTO = {
    performer: SelectedVendorRequestDTO;
    schedules: InHomeAppointmentMultiDayScheduleDTO[];
    note?: AppointmentNoteDTO;
    showOnPatient?: boolean;
    handoffId?: number;
};
export type InHomeAppointmentMultiDayScheduleDTO = {
    activityId: number;
    schedule: InHomeAppointmentScheduleDTO;
};
export type CreateGeneralAppointmentRequestDTO = {
    title: string;
    description?: string;
    generalAppointmentType?: string;
    /**
     * Format: date
     * @example 2019-10-24
     */
    startDate: string;
    /** @example 10:00 */
    startTime?: string;
    /** @example 11:00 */
    endTime?: string;
    /**
     * Format: date
     * @example 2019-10-28
     */
    endOfRecurrence?: string;
    recurrence: 'Once' | 'Daily' | 'Every Other Day' | 'Weekly';
    showOnPatient?: boolean;
    timeRangeAppointment: boolean;
    state: 'Scheduled' | 'Started' | 'Complete' | 'Cancelled' | 'Removed';
    attendees?: Attendees[];
    time?: TimeRangeDTO[];
    createInvitationLink?: boolean;
    startWhenLinkUsed?: boolean;
};
export type TimeRangeDTO = {
    /** @example 10:00 */
    startTime: string;
    /** @example 11:00 */
    endTime: string;
};
export type ActivityRescheduleRequestDTO = {
    /**
     * Format: date
     * @example 2019-10-24
     */
    scheduleDate: string;
    reason: string;
    comment?: string;
    carePlanId?: number;
    version?: number;
};
export type ActivityRequiredRequestDTO = {
    comment?: string;
    required: boolean;
    carePlanId?: number;
    version?: number;
};

export type PersonnelRoutingProfileDTO = {
    routingProfile: string;
};
export type PersonnelRolesDTO = {
    roleIds: string[];
};
export type PersonnelGeneralInfoDTO = {
    email: string;
    preferredEmail?: string;
    firstName: string;
    lastName: string;
};
export type PatientDischargeRequestV2DTO = {
    destinationCode?: string;
    /** Format: date */
    date?: string;
    comment?: string;
};
export type PatientActivateDTO = {
    podId: number;
};
export type AppointmentUncancellationRequestDTO = {
    reason?: string;
    note?: AppointmentNoteDTO;
};
export type StateDTO = {
    state?: string;
};
export type VoidDetailsDTO = {
    reason?: 'WRONG_TYPE' | 'WRONG_START_DATE' | 'ENTERED_BY_MISTAKE';
};
export type PatchProperties = {
    closeComment?: string;
};
export type AlternateUsernameDTO = {
    alternateUsername?: string;
};
export type VoidedDetailsDTO = {
    reason?: 'Void Demo Patient' | 'Patient Not Admitted';
    comment?: string;
};
export type RemoveMediaTagDTO = {
    tagCode: string;
};
export type MediaTagDTO = {
    tagCodes: string[];
};
export type ClusterEscalationDTO = {
    escalationIds: number[];
};
export type UpdateActivityLabelsRequestDTO = {
    inDayOccurrenceLabels?: {[key: string]: string};
};
export type ActivityWithFlagsResponseDTO = {
    id?: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    defaultForLateExecution?: number;
    completedDetails?: ActivityCompletedDetailsDTO;
    specification?: ActivitySpecification;
    action?: ActionResponseDTO;
    isInPreCheckInState?: boolean;
    inHomeAppointmentId?: number;
    inHomeAppointmentStatus?: InHomeAppointmentStatus;
    inHomeAppointmentStartDateTime?: string;
    inHomeAppointmentArrivalWindow?: string;
    activeHandOffId?: number;
    handOffType?: 'One day' | 'One action' | 'Mixed';
    scheduleDate?: string;
    scheduledTime?: string;
    cardinality?: number;
    required?: boolean;
    hasRequiredComments?: boolean;
    allowUndoConfirm?: boolean;
    allowUndoCheckIn?: boolean;
    allowUndoCheckOut?: boolean;
    allowEdit?: boolean;
    selfCheckInConfiguration?:
        | 'Never Allow'
        | 'Allow, confirmation call is always required'
        | 'Allow, confirmation call is not required';
    selfCheckOutConfiguration?:
        | 'Never Allow'
        | 'Allow, confirmation call is always required'
        | 'Allow, confirmation call is not required';
    relatedActivity?: boolean;
    multiDayActivity?: boolean;
    multipleDaysAndActivitiesFlowAvailable?: boolean;
    blockingInfo?: BlockedActivityDetailsDTO;
    isTBD?: boolean;
};

export type BlockedActivityDetailsDTO = {
    blockingDetails?: BlockedActivityInfo[];
};
export type BlockedActivityInfo = {
    code?: string;
};

export type HandOffResponseDTOV1 = {
    id?: number;
    type?: 'One day' | 'One action' | 'Mixed';
    status?: HandOffStatus;
    urgency?: HandOffUrgency;
    patient?: string;
    patientId?: number;
    mcrn?: string;
    instruction?: string;
    /** Format: date-time */
    createdDate?: string;
    /** Format: date */
    firstActivityDate?: string;
    /** Format: date */
    lastActivityDate?: string;
    orderActivity?: OrderActivityDTO[];
    vendors?: string[];
    incompleteRequestComment?: string;
};

export type OrderActivityDTO = {
    id?: number;
    scheduleDate?: string;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    orderName?: string;
    specification?: ActivitySpecificationDTO;
    relatedOrder?: number;
};

export type IncompleteHandOffRequestDTO = {
    comment?: string;
};
export type HandOffCancelRequestDTO = {
    reason?: 'Creating a new request' | 'Request created in error' | 'Other' | 'Autocancelled';
    comment?: string;
};

export type PageableObject = {
    offset?: number;
    sort?: string[];
    pageSize?: number;
    paged?: boolean;
    pageNumber?: number;
    unpaged?: boolean;
};

export type ActivityDetailsDTO = {
    activityId: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    orderId?: number;
    childOrderId?: number;
    orderName?: string;
    orderClinicalInfo?: string;
    orderComment?: string;
    orderNameCode?: string;
    flexibleScheduling?: boolean;
    openEnded?: boolean;
    activityCode?: string;
    category?: string;
    providerFirstName?: string;
    providerLastName?: string;
    duration?: string;
    secondaryDuration?: string;
    activityName?: string;
    description?: string;
    cardinality?: number;
    additionallyAdded?: boolean;
    nurseRequired?: boolean;
    includedInHandOff?: boolean;
    followupAction?: FollowupAction;
    scheduleDate?: string;
    defaultAppointmentArrivalWindow?: string;
    blockingInfo?: BlockedActivityDetailsDTO;
};
export type CheckInDetailsDTO = {
    /** Format: date-time */
    checkInTime?: string;
    actorFirstName?: string;
    actorLastName?: string;
};
export type CheckOutDetailsDTO = {
    /** Format: date-time */
    checkOutTime?: string;
};
export type InHomeAppointmentDTO = {
    patientId?: number;
    /** Format: date */
    startDate?: string;
    /** Format: date-time */
    startDateTime?: string;
    /** @example 10:00+00:00 */
    startTime?: string;
    /** @example 10:00+00:00 */
    endTime?: string;
    /** Format: date-time */
    endDateTime?: string;
    timeRangeAppointment?: boolean;
    note?: AppointmentNoteDTO;
    duration?: string;
    manualDuration?: boolean;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    showOnPatient?: boolean;
    actorFirstName?: string;
    actorLastName?: string;
    state?: string;
    patient?: PatientAppointmentResponseDTO;
    selfCheckOutConfiguration?: string;
    referrals?: InHomeAppointmentReferralsDTO[];
    clinicalWindowStart?: string;
    clinicalWindowEnd?: string;
    schedulingWindowStart?: string;
    schedulingWindowEnd?: string;
    instructions?: string;
    workOrderTaskNumber?: string;
    wheneverFeasible?: boolean;
};
export type InHomeAppointmentReferralsDTO = {
    id?: number;
    sent?: string;
    accepted?: string;
};
export type InHomeAppointmentResponseDTO = {
    id?: number;
    appointment?: InHomeAppointmentDTO;
    activities?: ActivityDetailsDTO[];
    performer?: PerformerDTO;
    checkIn?: CheckInDetailsDTO;
    checkOut?: CheckOutDetailsDTO;
    hasAdditionalActivities?: boolean;
    missedCheckInOut?: MissedCheckInOutDTO;
    changePartnerRequestId?: number;
    changePartnerSuggested?: boolean;
    modificationRequestId?: number;
    customArrivalWindowValues?: string[];
    inHomeAppointmentStatus?: InHomeAppointmentStatus;
};
export type MissedCheckInOutDTO = {
    reportOption?: MissedCheckInOutReportOption;
    doneTimingOption?: 'ON_TIME' | 'LATE';
};
export type PatientAppointmentResponseDTO = {
    id?: number;
    timeZone?: string;
    firstName?: string;
    lastName?: string;
    ehrMedicalRecordNumber?: string;
    /** Format: date */
    birthDate?: string;
    gender?: 'Male' | 'Female' | 'Other' | 'Unknown';
};
export type PerformerDTO = {
    siteCode?: string;
    siteName?: string;
    vendorCode?: string;
    vendorName?: string;
    actor?: ActorSearchResponseDTO;
    serviceNowIntegrationEnabled?: boolean;
};
export type ArchivedPersonnelDTO = {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    roles?: string[];
    states?: string[];
};
export type PageArchivedPersonnelDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: ArchivedPersonnelDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type ActivePersonnelDTO = {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    clusters?: string[];
};
export type PageActivePersonnelDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: ActivePersonnelDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type EducationDocumentPackageHistoryDTO = {
    event?: 'Package created' | 'Package edited' | 'Package archived' | 'Package unarchived';
    /** Format: date-time */
    eventDateTime?: string;
    personnelFullName?: string;
    changeDetails?: EducationPackageHistoryChangeDetails;
};
export type EducationPackageHistoryChangeDetails = {
    changes?: ChangeDetail[];
    filesChanges?: {[key: string]: ChangeDetail[]};
};

export type VendorRefuseReason = {
    reason: string;
    accepted: boolean;
};

export type Change = {
    changeType: string;
};

export type ReferralDetails = {
    display: string;
    oldValue: string;
    newValue: string;
};

export type VendorItem = {vendorName: string; siteName: string; actorName: string};

export type ChangeFile = {
    oldValue?: string;
    newValue?: string;
};
export type ChangeValue = {
    oldValue?: string;
    newValue?: string;
};
export type FileName = {
    value?: string;
};
export type NewValue = {
    value?: string;
};

export type ChangeDetail = {
    label?: string;
    //TODO fix this after BE will be fixed
    displayLabel: string;
    change: {
        values?: string[];
        changeType: string;
        oldValue?: string;
        newValue?: string;
        value?: string;
        oldStart?: string;
        oldEnd?: string;
        newStart?: string;
        newEnd?: string;
        type?: string;
        items?: ChecklistItem[];
        activities?: {
            type?: string;
            label?: string;
            answer?: string;
            activityName?: string;
            activityLabel?: string;
            activityInDayOccurrence?: number;
            orderName?: string;
            activityId?: number;
            orderId?: number;
            vendorRefuseReason?: VendorRefuseReason;
            status?: string;
            receiptDateTime?: string;
            comment?: string;
            refuseReason?: string;
            refuseComment?: string;
            confirmed?: boolean;
            needToBeRescheduled?: boolean;
            providerChecklist?: {
                type?: string;
                items?: {label?: string; answer?: string}[];
            };
            items?: ChecklistItem[];
            nurseChecklist?: {
                type?: string;
                items?: {label?: string; answer?: string}[];
            };
        }[];
        rating?: number;
        current?: VendorItem;
        suggested?: VendorItem[];
        referralSent?: ReferralDetails;
        referralAccepted?: ReferralDetails;
        left?: string;
        right?: string;
    };
};

export type Category = {
    name?: string;
};
export type CategoryDTO = {
    name?: string;
};
export type OrderSpecificationDTO = {
    ehr?: string;
    product?: ProductDTO;
    isCustom?: boolean;
    code?: string;
    category?: Category;
    name?: string;
    orderType?: 'HOME' | 'GENERAL' | 'BOTH';
    strength?: string;
    strengthUnits?: string;
    route?: string;
    defaultFlexibleScheduling?: boolean;
};
export type OrderSpecificationModelDTO = {
    categories?: CategoryDTO[];
    specifications?: OrderSpecificationDTO[];
};
export type ProductDTO = {
    label?: string;
    code?: string;
};
export type OrderFrequencyDTO = {
    name?: string;
    label?: string;
};
export type ActivityBundleSpecificationDTO = {
    bundleId?: string;
    activities?: ContainedActivityDTO[];
};
export type ContainedActivityDTO = {
    activitySpecification?: ActivitySpecificationDTO;
    ignoreFrequency?: boolean;
    oncePerOrder?: boolean;
    /** Format: int32 */
    cardinality?: number;
    isTBD?: boolean;
    isPRN?: boolean;
};
export type OrderAttributesDTO = {
    attribute?: string;
    attributeValue?: string;
    attributeLocation?: string;
    question?: string;
    questionAnswer?: string;
    bundleSpecification?: ActivityBundleSpecificationDTO;
    orderType?: 'HOME' | 'GENERAL' | 'BOTH';
};

export type SimpleOrderDTO = {
    orderName: string;
    activities: string[];
    activityDetails: {
        inDayOccurrence: number;
        inDayOccurrences: number;
        label: string;
        name: string;
    }[];
};

export type HandOffResponseDTO = {
    discharge?: PatientDischargeResponseDTO;
    id?: number;
    urgency?: HandOffUrgency;
    patient?: string;
    patientId?: number;
    createdDate?: string;
    firstActivityDate?: string;
    lastActivityDate?: string;
    orderName?: string;
    orderNames?: string[];
    vendors?: HandOffVendorResponseDTO[];
    activitiesCount?: number;
    ordersCount?: number;
    type?: 'One day' | 'One action' | 'Mixed';
    status?: HandOffStatus;
    currentVendor?: string;
    incompleteRequestComment?: string;
    activePrecautions?: BasePatientPrecautionDTO[];
    spiStatus?: 'IN_PROGRESS' | 'DONE' | 'FAILED';
    serviceCoordinator?: PersonnelDTO;
    recurring?: boolean;
    timeOfDay?: TimeOfDay;
    wheneverFeasible?: boolean;
    orders?: SimpleOrderDTO[];
    patientType?: PatientTypeOptionsDTO;
    workOrderTaskNumber?: string;
};
export type PageHandOffResponseDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: HandOffResponseDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type HandOffDetailResponseDTO = {
    id?: number;
    type?: 'One day' | 'One action' | 'Mixed';
    status?: HandOffStatus;
    patientId?: number;
    patientTimeZone?: string;
    instruction?: string;
    earliestTime?: string;
    latestTime?: string;
    orders?: HandOffOrderDTO[];
    mcrnfullName?: string;
    changeReason?: string;
    appointmentDuration?: string;
    arrivalWindow?: string;
    schedulingWindowStart?: string;
    schedulingWindowEnd?: string;
    wheneverFeasible?: boolean;
};
export type HandOffOrderDTO = {
    id?: number;
    name?: string;
    clinicalInfo?: string;
    comment?: string;
    frequencyLabel?: string;
    inDayOccurrences?: number;
    /** Format: date */
    startDate?: string;
    /** Format: date */
    endDate?: string;
    openEnded?: boolean;
    flexibleScheduling?: boolean;
    recurring?: boolean;
    activities?: {
        [key: string]: OrderActivityDTO[];
    };
};
export type HandOffVendorResponseDTO = {
    siteCode?: string;
    siteName?: string;
    vendorCode?: string;
    vendorName?: string;
    actor?: ActorSearchResponseDTO;
    activities?: ActivitySearchResponseDTO[];
    notAvailable?: boolean;
    notAvailableReason?: UnavailableServiceProvider;
    sequence?: number;
    alreadyHasSuitableAppointment?: boolean;
    id?: number;
    substatus?: VendorSubstatus;
    spiActionsData?: VendorOptionSPIDataDTO[];
    referralSentDate?: string | null;
    referralAcceptedDate?: string | null;
    activityCodes?: string[];
    vendorActorKey?: VendorActorKey;
};
export type PreAppointmentDTO = {
    handOffResponseDTO?: HandOffDetailResponseDTO;
    actors?: HandOffVendorResponseDTO[];
};
export type VendorOptionSPIDataDTO = {
    type?: 'SEND_REFERRAL_DOCUMENTATION' | 'SEND_APPOINTMENT_REQUEST';
    enabled?: boolean;
    status?: 'IN_PROGRESS' | 'DONE' | 'FAILED';
    timestamp?: string;
};
export type HandOffOverviewResponseDTO = {
    id?: number;
    urgency?: HandOffUrgency;
    patientId?: number;
    createdDate?: string;
    type?: 'One day' | 'One action' | 'Mixed';
    status?: HandOffStatus;
    vendors?: VendorOption[];
    instruction?: string;
    earliestTime?: string;
    latestTime?: string;
    mcrnfullName?: string;
    changeReason?: string;
};

export type VendorOption = {
    id?: number;
    vendorName?: string;
    substatus?: VendorSubstatus;
    activities?: OrderActivityDTO[];
    spiActionsData?: VendorOptionSPIDataDTO[];
    sequence?: number;
};
export type AppointmentCancellationServiceRequestResponseDTOV2 = {
    id?: number;
    urgency?: HandOffUrgency;
    patient?: string;
    patientId?: number;
    /** Format: date-time */
    createdDate?: string;
    /** Format: date */
    firstActivityDate?: string;
    /** Format: date */
    lastActivityDate?: string;
    orderName?: string;
    vendors?: string[];
    activitiesCount?: number;
    ordersCount?: number;
    appointmentId?: number;
    status?: CancellationRequestStatus;
    reason?: string;
    comment?: string;
    appointmentReplaced?: boolean;
    serviceCoordinator?: SimplePersonnelDTO;
    orders?: SimpleOrderDTO[];
    patientType?: PatientTypeOptionsDTO;
};
export type PageAppointmentCancellationServiceRequestResponseDTOV2 = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: AppointmentCancellationServiceRequestResponseDTOV2[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type AppointmentCancellationServiceRequestResponseOverviewDTO = {
    id?: number;
    urgency?: HandOffUrgency;
    patientId?: number;
    createdDate?: string;
    appointmentId?: number;
    status?: CancellationRequestStatus;
    mcrn?: string;
    reason?: string;
    comment?: string;
    vendors?: Vendor[];
};
export type Vendor = {
    id?: number;
    vendorName?: string;
    substatus?: VendorSubstatus;
    activities?: OrderActivityDTO[];
    spiActionsData?: VendorOptionSPIDataDTO[];
};
export type Identity = {
    connection?: string;
    user_id?: string;
    provider?: string;
    isSocial?: boolean;
    access_token?: string;
    profileData?: ProfileData;
};
export type ProfileData = {
    email?: string;
    email_verified?: boolean;
    name?: string;
    username?: string;
    given_name?: string;
    phone_number?: string;
    phone_verified?: boolean;
    family_name?: string;
};
export type User = {
    connection?: string;
    client_id?: string;
    password?: string;
    verify_password?: boolean;
    username?: string;
    email?: string;
    email_verified?: boolean;
    verify_email?: boolean;
    phone_number?: string;
    phone_verified?: boolean;
    verify_phone_number?: boolean;
    user_id?: string;
    picture?: string;
    name?: string;
    nickname?: string;
    given_name?: string;
    family_name?: string;
    created_at?: string;
    updated_at?: string;
    identities?: Identity[];
    app_metadata?: {[key: string]: {[key: string]: unknown}};
    user_metadata?: {[key: string]: {[key: string]: unknown}};
    multifactor?: string[];
    last_ip?: string;
    last_login?: string;
    logins_count?: number;
    blocked?: boolean;
    roles?: string[];
    sub?: string;
};
export type UsersPage = {
    items?: User[];
    start?: number;
    length?: number;
    total?: number;
    limit?: number;
};
export type ActivityTreeDTO = {
    name?: string;
    code?: string;
    availableForScheduling?: boolean;
};
export type ActorTreeDTO = {
    code?: string;
    name?: string;
    availableForScheduling?: boolean;
    activities?: ActivityTreeDTO[];
};
export type SiteTreeDTO = {
    code?: string;
    name?: string;
    availableForScheduling?: boolean;
    actors?: ActorTreeDTO[];
    enterpriseId?: string;
};
export type VendorTreeDTO = {
    code?: string;
    name?: string;
    availableForScheduling?: boolean;
    sites?: SiteTreeDTO[];
    enterpriseId?: string;
    visible?: boolean;
};
export type AppointmentSiteDTO = {
    vendorName?: string;
    siteName?: string;
    siteCode?: string;
};

// Type of the BaseServiceAreaDTO updated manually because of inconsistency of type from backend and actual response
export type BaseServiceAreaDTO = ServiceAreaTownsDTO | ServiceAreaZipsDTO | GrandServiceAreaResponseDTO;

export type GrandServiceAreaResponseDTO = {
    id?: number;
    name: string;
    description?: string;
    enabledInVendorDictionary: boolean;
    activeForPatients: boolean;
    type?: string;
    /** Format: date-time */
    updatedDate?: string;
    includedAreas?: BaseServiceAreaDTO[];
};
export type PageBaseServiceAreaDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: BaseServiceAreaDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type InstancePropsResponseDto = {
    code?: string;
    label?: string;
    id?: number;
};
export type PageReferringFacilityResponse = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: ReferringFacilityResponse[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    last?: boolean;
    first?: boolean;
    empty?: boolean;
};
export type ReferringFacilityResponse = {
    id?: number;
    name?: string;
    comment?: string;
    archived?: boolean;
};
export type ReferringFacilityOverviewResponse = {
    name?: string;
};
export type CreateReferringFacilityRequest = {
    name?: string;
    comment?: string;
};

export type NurseOption = 'AN not displayed' | 'Show AN Full Name' | 'Show AN First Name';

export type ProductConfigurationResponseDTOv2 = {
    id?: number;
    product?: InstancePropsDTO;
    showNurseOption?: NurseOption;
};
export type PrecautionDTO = {
    id?: number;
    name?: string;
};
export type PatientPrecautionOverviewDTO = {
    patientPrecautions?: PatientPrecautionDTO[];
    lastUpdatedAt?: string;
    lastUpdatedBy?: string;
};
export type PatientPrecautionHistoryDTO = {
    eventDateTime: string;
    event:
        | 'Added Precaution'
        | 'Edited Precaution'
        | 'Voided Precaution'
        | 'Inactivated Patient'
        | 'Discontinued Precaution';
    personnelFullName: string;
    changeDetails?: PatientPrecautionHistoryChangeDetails;
};
export type PatientPrecautionHistoryResponseDTO = {
    startDate?: string;
    endDate?: string;
    name?: string;
    histories?: PatientPrecautionHistoryDTO[];
};
export type BaseTaskResponseDTO = {
    id?: number;
    patientId: number;
    name: string;
    /** Format: date-time */
    dueDate: string;
    taskState?: 'Open' | 'Started' | 'Cancelled' | 'Done';
    scratchPad?: string;
    taskType?: string;
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    documents?: Document[];
    checklist?: TemplateChecklistDTO;
    comment?: string;
    category?: string;
};
export type PageTaskHistoryViewDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: TaskHistoryViewDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type TaskHistoryViewDTO = {
    id?: number;
    name: string;
    /** Format: date-time */
    dueDate: string;
    taskState?: 'Open' | 'Started' | 'Cancelled' | 'Done';
    taskType?: string;
    hasChecklist?: boolean;
    /** Format: date-time */
    createdDate?: string;
    /** Format: date-time */
    completedDate?: string;
    updatedBy?: string;
    category?: string;
};
export type TaskCount = {
    count?: number;
    hasOverdueTasks?: boolean;
};
export type OrderAutoCreateTaskDTO = {
    id?: number;
    name?: string;
};
export type Appointment = {
    id?: number;
    type?: 'General' | 'In Home';
    startDateTime?: string;
    title?: string;
    showOnPatient?: boolean;
};
export type HospitalInfo = {
    hospitalDays?: number;
    lengthOfStay?: number;
    lastBiometricReading?: number;
    lastBiometricIncident?: string;
};
export type PersonnelPatientsResponseDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    birthDate?: string;
    ehrMedicalRecordNumber?: string;
    sex?: 'Male' | 'Female' | 'Other' | 'Unknown';
    personnel?: Personnel;
    timeZone?: string;
    discharge?: PatientDischargeResponseDTO;
    patientRecentStatistic?: PatientRecentStatisticDTO;
    hospitalInfo?: HospitalInfo;
    nextEvent?: Appointment;
    activePrecautions?: BasePatientPrecautionDTO[];
    patientType?: PatientTypeOptionsDTO;
};
export type PersonnelPatientsCountResponseDTO = {
    patientsCount?: number;
};
export type AppointmentAgendaItemDTO = {
    generalAppointmentType?:
        | 'Video Visit'
        | 'Phone Call'
        | 'Vital Signs'
        | 'Patient Reminder/Message'
        | 'Facility Visit'
        | 'Other';
    state?: 'Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation';
    status?: string;
    startTime?: {
        offset?: {
            totalSeconds?: number;
            id?: string;
            rules?: {
                transitions?: {
                    gap?: boolean;
                    dateTimeBefore?: string;
                    dateTimeAfter?: string;
                    duration?: string;
                    instant?: string;
                    overlap?: boolean;
                }[];
                transitionRules?: {
                    month?:
                        | 'JANUARY'
                        | 'FEBRUARY'
                        | 'MARCH'
                        | 'APRIL'
                        | 'MAY'
                        | 'JUNE'
                        | 'JULY'
                        | 'AUGUST'
                        | 'SEPTEMBER'
                        | 'OCTOBER'
                        | 'NOVEMBER'
                        | 'DECEMBER';
                    timeDefinition?: 'UTC' | 'WALL' | 'STANDARD';
                    dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
                    dayOfMonthIndicator?: number;
                    localTime?: LocalTime;
                    midnightEndOfDay?: boolean;
                }[];
                fixedOffset?: boolean;
            };
        };
        nano?: number;
        hour?: number;
        minute?: number;
        second?: number;
    };
    startDateTime?: string;
    endTime?: {
        offset?: {
            totalSeconds?: number;
            id?: string;
            rules?: {
                transitions?: {
                    gap?: boolean;
                    dateTimeBefore?: string;
                    dateTimeAfter?: string;
                    duration?: string;
                    instant?: string;
                    overlap?: boolean;
                }[];
                transitionRules?: {
                    month?:
                        | 'JANUARY'
                        | 'FEBRUARY'
                        | 'MARCH'
                        | 'APRIL'
                        | 'MAY'
                        | 'JUNE'
                        | 'JULY'
                        | 'AUGUST'
                        | 'SEPTEMBER'
                        | 'OCTOBER'
                        | 'NOVEMBER'
                        | 'DECEMBER';
                    timeDefinition?: 'UTC' | 'WALL' | 'STANDARD';
                    dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
                    dayOfMonthIndicator?: number;
                    localTime?: LocalTime;
                    midnightEndOfDay?: boolean;
                }[];
                fixedOffset?: boolean;
            };
        };
        nano?: number;
        hour?: number;
        minute?: number;
        second?: number;
    };
    endDateTime?: string;
    startDate?: string;
    timeCalculated?: boolean;
    timeZone?: string;
    id?: number;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    expectedDuration?: string;
    type?: string;
    patientId?: number;
    title?: string;
    inHomeState?: 'Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation';
    inHomeStatus?: InHomeAppointmentStatus;
    generalState?: 'Scheduled' | 'Started' | 'Completed' | 'Cancelled' | 'Removed';
    showOnPatient?: boolean;
};

export type PersonnelPatientsAppointmentsResponseDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    timeZone?: string;
    personnel?: Personnel;
    appointment?: AppointmentAgendaItemDTO;
};
export type PersonnelPatientsAppointmentsCountResponseDTO = {
    appointmentsCount?: number;
};

export type MeetingStatuses = 'CREATED' | 'STARTED' | 'ENDED' | 'CANCELED' | 'SUSPENDED';

export type ActiveUserStatus = 'JOINING' | 'JOINED' | 'LEFT' | 'JBH_JOINED' | 'TO_BE_LEFT';

export type VideoMeetingStatusDTO = {
    meetingStatus?: MeetingStatuses;
    activeUserStatus?: ActiveUserStatus;
};

export type CallToPatientDTO = {
    patientJoined?: boolean;
    meetingStatus?: 'CREATED' | 'STARTED' | 'ENDED' | 'CANCELED' | 'SUSPENDED';
    startUrl?: string;
    joinUrl?: string;
};
export type PageVisitLogDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: VisitLogDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type VisitLogDTO = {
    patientId?: number;
    patientFullName?: string;
    ehrMedicalRecordNumber?: string;
    initialPoint?: string;
    /** Format: date-time */
    visitedDateTime?: string;
    userFullName?: string;
    userEmail?: string;
};
export type TimeZoneDTO = {
    id?: string;
    displayName?: string;
    offset?: string;
};

export type RoutingProfileSummaryDTO = {
    id?: string;
    name?: string;
};
export type Role = {
    id?: string;
    name?: string;
    description?: string;
};
export type Info = {
    ehrMedicalRecordNumber?: string;
    admitDate?: string;
    primaryEHREncounterId?: string;
    secondaryEHREncounterId?: string;
    referringFacilityName?: string;
    referringFacilityMrn?: string;
};
export type PagePatientOverviewResponseDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: PatientOverviewResponseDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type PatientOverviewResponseDTO = {
    id?: number;
    missionControlId?: number;
    age?: number;
    podId?: number;
    pod?: string;
    currentPod?: PodDTO;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    birthDate?: string;
    active?: boolean;
    patientType?: PatientTypeOptionsDTO;
    primaryLanguage?: LanguageDTO;
    sex?: 'Male' | 'Female' | 'Other' | 'Unknown';
    product?: string;
    sponsor?: string;
    info?: Info;
    personnel?: Personnel;
    state?: string;
    timeZone?: string;
    discharge?: PatientDischargeResponseDTO;
    patientRecentStatistic?: PatientRecentStatisticDTO;
    voidedDetails?: VoidedDetailsResponseDTO;
    phaseOfCare?: string;
    /**
     * Format: int32
     * @deprecated
     */
    lengthOfStay?: number;
    hospitalInfo?: HospitalInfo;
    onPhoneCall?: boolean;
    activePrecautions?: BasePatientPrecautionDTO[];
};
export type VoidedDetailsResponseDTO = {
    reason?: 'Void Demo Patient' | 'Patient Not Admitted';
    comment?: string;
    /** Format: date-time */
    date?: string;
    voidedBy?: string;
};
export type DeactivationCount = {
    name?: string;
    count?: number;
    critical?: boolean;
};
export type PatientDeactivationInfo = {
    deactivationCountList?: DeactivationCount[];
    assignedNurseId?: number;
    assignedNurseName?: string;
};
export type BatteryStatusDTO = {
    state: 'UNPLUGGED' | 'CHARGING';
    level: number;
};
export type AssignmentCheckDTO = {
    reassignmentNeeded?: boolean;
};
export type PatientForVendorSearchDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    mrn?: string;
    zip?: string;
    city?: string;
    state?: string;
    payor?: string;
    sponsor?: string;
};
export type PatientFullNameDTO = {
    id?: number;
    fullName?: string;
};
export type PatientScratchpadHistoryDTO = {
    id?: number;
    personnelFullname?: string;
    /** Format: date-time */
    dateTime?: string;
};
export type ShiftAssignmentHistoryRecordDTO = {
    assignedTo?: string;
    assignedBy?: string;
    /** Format: date-time */
    start?: string;
    /** Format: date-time */
    end?: string;
};
export type AssignedPersonnelDTO = {
    firstName?: string;
    lastName?: string;
    id?: number;
};
export type PagePatientAssignmentDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: PatientAssignmentDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type PatientAssignmentDTO = {
    id?: number;
    firstName?: string;
    lastName?: string;
    preferredName?: string;
    gender?: 'Male' | 'Female' | 'Other' | 'Unknown';
    /** Format: date */
    birthDate?: string;
    ehrMedicalRecordNumber?: string;
    patientType?: PatientTypeOptionsDTO;
    discharge?: PatientDischargeResponseDTO;
    activePrecautions?: BasePatientPrecautionDTO[];
    assignedNurse?: AssignedPersonnelDTO;
    assignedServiceCoordinator?: AssignedPersonnelDTO;
    assignedPhysician?: AssignedPersonnelDTO;
    assignedClinician?: AssignedPersonnelDTO;
    product?: string;
    sponsor?: string;
    pod?: PodDTO;
};
export type PatientAvailableAssignmentsDTO = {
    recentPersonnel?: PersonnelDTO[];
    depotTechnicians?: PersonnelDTO[];
    availablePersonnel?: PersonnelDTO[];
};
export type AvailableAssignmentsDTO = {
    nurses?: PersonnelDTO[];
    serviceCoordinators?: PersonnelDTO[];
    attendingPhysicians?: PersonnelDTO[];
    responsibleClinicians?: PersonnelDTO[];
};
export type MissionControlRoutingProfileSetupStatus = {
    presentRoutingProfiles?: string[];
    missingRoutingProfiles?: string[];
    completed?: boolean;
};
export type MissionControlQueueSetupStatus = {
    presentQueues?: string[];
    missingQueues?: string[];
    completed?: boolean;
};
export type MissionControlIdentifierStatusDTO = {
    identifier?: string;
    available?: boolean;
};

export type CallHistoryViewDTO = {
    callType?: string;
    /** Format: date-time */
    startTime?: string;
    /** Format: date-time */
    endTime?: string;
    duration?: number;
    participantsCount?: number;
    participants?: string;
    initiationMethod?: string;
    patientNumber?: string;
    emergencyCall?: boolean;
};
export type PageCallHistoryViewDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: CallHistoryViewDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type RegistrationTokenDTO = {
    token?: string;
    qrCodePngImageBase64?: string;
};
export type VisualDocumentationResponseDTO = {
    photoId?: string;
    description?: string;
    contentType?: string;
    size?: number;
    /** Format: date-time */
    created?: string;
    data?: string;
};
export type CradlepointConnected = {
    connected?: boolean;
    description?: string;
};
export type ExistingWiFiResponseDTO = {
    wiFiName?: string;
    networkPassword?: string;
    noWiFiCredentials?: boolean;
    noWiFi?: boolean;
    noWiFiCredentialsDescription?: string;
    wifiCredentialsImage?: VisualDocumentationResponseDTO[];
};
export type InstallationInformation = {
    name?: string;
    company?: string;
    /** Format: date */
    date?: string;
    /** Format: date-time */
    arriveTime?: string;
    /** Format: date-time */
    endTime?: string;
};
export type PostInstallationCheckListResponseDTO = {
    installationInformation?: InstallationInformation;
    visualDocumentation?: VisualDocumentationResponseDTO[];
    broadbandConnection?: string[];
    cradlepointConnected?: CradlepointConnected;
    existingWiFi?: ExistingWiFiResponseDTO;
};
export type EquipmentKitWithDependenciesResponseDTO = {
    id?: number;
    name?: string;
    equipmentKitType?: string;
    equipmentKitSpecificationId?: string;
    status?: EquipmentKitStatus;
    comment?: string;
    patient?: Patient;
    /** Format: date-time */
    patientAssignedDateTime?: string;
    homeLocation?: EquipmentDepotResponseDTO;
    currentLocation?: EquipmentDepotResponseDTO;
    hasDevices?: boolean;
    demo?: boolean;
};
export type EquipmentKitStatusChangeResponseDTO = {
    id?: number;
    /** Format: date-time */
    dateTime?: string;
    equipmentKitId?: number;
    comment?: string;
    previousStatus?: EquipmentKitStatus;
    newStatus?: EquipmentKitStatus;
    updatedBy?: string;
};
export type Diff = {
    field?: string;
    newValue?: string;
    oldValue?: string;
};
export type KitHistoryValuesDTO = {
    id?: number;
    demo?: boolean;
    name?: string;
    status?: string;
    comment?: string;
    deleted?: boolean;
    created_by?: string;
    patient_id?: number;
    updated_by?: string;
    created_date?: string;
    updated_date?: string;
    current_location?: string;
    home_location_id?: number;
    current_location_id?: number;
    equipment_specification_id?: string;
    patient_assigned_date_time?: string;
};
export type EquipmentKitHistoryEntryResponseDTO = {
    id?: number;
    updateType?: string;
    /** Format: date-time */
    dateTime?: string;
    updatedBy?: string;
    parentId?: number;
    originalValues?: KitHistoryValuesDTO;
    newValues?: KitHistoryValuesDTO;
    diffs?: Diff[];
};
export type UpdateDeviceResponseDTO = {
    id?: number;
    serialNumber?: string;
    model?: DeviceModelDTO;
};
export type DeviceUpdateResponseDTO = {
    id?: number;
    action?: 'ADDED' | 'REMOVED';
    device?: UpdateDeviceResponseDTO;
    equipmentKitId?: number;
    comment?: string;
    /** Format: date-time */
    dateTime?: string;
    updatedBy?: string;
};
export type EquipmentKitSpecification = {
    name?: string;
    id?: string;
    requiredDeviceTypes?: string[];
};
export type PageDeviceResponseDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: DeviceResponseDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};

export type Pageable = {
    offset?: number;
    sort?: string[];
    pageNumber?: number;
    unpaged?: boolean;
    pageSize?: number;
    paged?: boolean;
};
export type WifiPhoneValidationResponse = {
    id?: string;
    status?: string;
    message?: string;
    deviceName?: string;
};
export type DeviceTypeSpecificFieldSpecification = {
    deviceType?: string;
    fields?: FieldSpecification[];
};
export type FieldSpecification = {
    name?: string;
    label?: string;
    type?: 'TEXT';
    arrayItemsType?: 'TEXT';
    required?: boolean;
    editable?: boolean;
};
export type PrecautionDetailsDTO = {
    id?: number;
    name?: string;
    voided?: boolean;
    isDefault?: boolean;
};
export type FeatureApiBean = {
    uid?: string;
    description?: string;
    group?: string;
    flippingStrategy?: FlippingStrategyApiBean;
    permissions?: string[];
    customProperties?: {
        [key: string]: PropertyApiBean;
    };
    enable?: boolean;
};
export type FlippingStrategyApiBean = {
    type?: string;
    initParams?: {[key: string]: string};
};
export type PropertyApiBean = {
    name?: string;
    description?: string;
    type?: string;
    value?: string;
    fixedValues?: string[];
};
export type EducationDocumentDTO = {
    id?: number;
    title?: string;
    type?: 'Program Information' | 'Patient Education';
    sponsors?: string[];
    products?: string[];
    fileName?: string;
    /** Format: date-time */
    createdDate?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    createdBy?: string;
    archived?: boolean;
};
export type EducationDocumentHistoryDTO = {
    title?: string;
    type?: 'Program Information' | 'Patient Education';
    sponsors?: string[];
    products?: string[];
    fileName?: string;
    /** Format: date-time */
    updatedDate?: string;
    updatedBy?: string;
    archived?: boolean;
};
export type UiManagedConfigurationDTO = {
    name?: string;
    label?: string;
    boolValue?: boolean;
};
export type ClusterOverviewResponseDTO = {
    id?: number;
    name?: string;
    missionControlId?: number;
    statesSpecified?: boolean;
    handleUnknownCallers: boolean;
    states?: string[];
    escalations?: string[];
};
export type ChangePartnerRequestDetailsDTO = {
    handOffResponseDTO?: HandOffDetailResponseDTO;
    actors?: HandOffVendorResponseDTO[];
    currentVendor?: PerformerDTO;
    appointment?: InHomeAppointmentDTO;
};
export type PageServiceRequestResponseDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: ServiceRequestResponseDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type ServiceRequestResponseDTO = {
    id?: number;
    serviceRequestId?: number;
    urgency?: HandOffUrgency;
    type?: 'SCHEDULING' | 'CHANGE_VENDOR' | 'CANCELLATION';
    status?: 'Not started' | 'In progress' | 'Pending Cancellation' | 'All';
    createdDate?: string;
    firstActivityDate?: string;
    lastActivityDate?: string;
    patient?: Patient;
    vendors?: string[];
    isMultiMulti?: boolean;
};
export type PageTaskWidgetDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: TaskWidgetDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type TaskWidgetDTO = {
    id?: number;
    name?: string;
    patientTimeZone?: string;
    patientId?: string;
    patientFullName?: string;
    /** Format: date-time */
    dueDate?: string;
    taskState?: 'Open' | 'Started' | 'Cancelled' | 'Done';
    taskType?: string;
};
export type MyPageCounters = {
    widgetCounters?: {[key: string]: number};
};
export type AppointmentPreDocumentedWidgetDTO = {
    id?: number;
    /** Format: date-time */
    checkOutTime?: string;
    patientId?: number;
    patientTimeZone?: string;
    site?: string;
    actor?: string;
    patientFirstName?: string;
    patientLastName?: string;
};
export type PageAppointmentPreDocumentedWidgetDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: AppointmentPreDocumentedWidgetDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type MyPageEmptyAppointmentDTO = {
    id?: number;
    startDate?: string;
    startDateTime?: string;
    startTime?: string;
    endTime?: string;
    endDateTime?: string;
    actualStartDateTime?: string;
    actualEndDateTime?: string;
    patient?: PatientAppointmentResponseDTO;
    performer?: PerformerDTO;
    state?: string;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
};
export type PageMyPageEmptyAppointmentDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: MyPageEmptyAppointmentDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type RelatedOrderDTO = {
    id?: number;
    orderName?: string;
    category?: string;
    orderNameCode?: string;
    orderId?: string;
    comment?: string;
    providerFirstName?: string;
    providerLastName?: string;
    state?: OrderState;
    frequency?: Frequency;
    inDayOccurrences?: number;
    originalFrequencyText?: string;
    activities?: SelectableActivityResponseDTO[];
};
export type SelectableActivityResponseDTO = {
    id?: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    orderId?: number;
    specification?: ActivitySpecification;
    /** Format: date */
    scheduleDate?: string;
    selectable?: boolean;
    nextNotCompletedAction?: ActionResponseDTO;
    cardinality?: number;
};
export type MultipleDaysRelatedActivityResponseDTO = {
    id?: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    name?: string;
    code?: string;
    orderId?: number;
    scheduleDate?: string;
    required?: boolean;
    blockingInfo?: BlockedActivityDetailsDTO;
    selectable?: boolean;
    oncePerOrder?: boolean;
    nurseRequired?: boolean;
    cardinality?: number;
    activeHandoffId?: number;
    inHomeAppointmentId?: number;
    childOrderId?: number;
    defaultAppointmentArrivalWindow?: string;
};
export type MultipleDaysRelatedOrderBundleDTO = {
    relatedOrders?: MultipleDaysRelatedOrderDTO[];
    inactiveRelatedOrdersCount?: number;
};
export type MultipleDaysRelatedOrderDTO = {
    id?: number;
    category?: string;
    orderNameCode?: string;
    orderName?: string;
    comment?: string;
    clinicalInfo?: string;
    providerFirstName?: string;
    providerLastName?: string;
    state?: OrderState;
    frequency?: Frequency;
    inDayOccurrences?: number;
    originalFrequencyText?: string;
    flexibleSchedulingOrder?: boolean;
    openEnded?: boolean;
    /** Format: date */
    startDate?: string;
    /** Format: date */
    endDate?: string;
    activities?: {
        [key: string]: MultipleDaysRelatedActivityResponseDTO[];
    };
};
export type MultiRelatedOrderDTO = {
    id?: number;
    category?: string;
    orderNameCode?: string;
    comment?: string;
    providerFirstName?: string;
    providerLastName?: string;
    state?: OrderState;
    frequency?: Frequency;
    inDayOccurrences?: number;
    originalFrequencyText?: string;
    activities?: {
        [key: string]: SelectableActivityResponseDTO[];
    };
};
export type ActivityOverviewDTO = {
    activityName?: string;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    cardinality?: number;
    followupAction?: FollowupAction;
    vendorName?: string;
    role?: string;
    inAppointment?: boolean;
    /** Format: date */
    scheduleDate?: string;
    removable?: boolean;
};
export type OrderChangeDTO = {
    newComment?: string;
    newClinicalInfo?: string;
    /** Format: date-time */
    newEndDateTime?: string;
    order?: OrderDTO;
    activities?: ActivityOverviewDTO[];
};
export type OrderDTO = {
    id?: number;
    category?: string;
    subcategory?: string;
    orderName?: string;
    comment?: string;
    frequency?: Frequency;
    originalFrequencyText?: string;
    providerFirstName?: string;
    providerLastName?: string;
    /** Format: date */
    startDate?: string;
    /** Format: date-time */
    startDateTime?: string;
    /** Format: date-time */
    endDateTime?: string;
    /** Format: date */
    endDate?: string;
    patientId?: number;
};
export type OrderTypeResponseDTO = {
    type?: string;
};
export type OrderResponseDTO = {
    id?: number;
    orderType?: 'HOME' | 'GENERAL' | 'BOTH';
    category?: string;
    source?: string;
    orderNameCode?: string;
    orderName?: string;
    clinicalInfo?: string;
    comment?: string;
    patientId?: number;
    patientTimeZone?: string;
    frequency?: Frequency;
    inDayOccurrences?: number;
    originalFrequencyText?: string;
    startDate?: string;
    endDate?: string;
    providerFirstName?: string;
    providerLastName?: string;
    certifiedByFirstName?: string;
    certifiedByLastName?: string;
    acknowledged?: boolean;
    autoAcknowledged?: boolean;
    acknowledgedDateTime?: string;
    acknowledgedBy?: string;
    canceledBy?: string;
    acknowledgeCancellation?: string;
    cancelEffectiveDateTime?: string;
    cancelActivitiesInEffectiveDay?: boolean;
    state?: AcknowledgeOrderCancelDTO;
    subcategory?: string;
    strength?: string;
    strengthUnits?: string;
    route?: string;
    history?: OrderHistoryDTO[];
    upcomingActionDate?: string;
    flexibleScheduling?: boolean;
    addingActivitiesAllowed?: boolean;
    addingPRNActivitiesAllowed?: boolean;
    openEnded?: boolean;
    defaultFlexibleScheduling?: boolean;
    primary?: boolean;
    ehrEncounterId?: string;
    ehrOrderNumber?: string;
    createdDate?: string;
    startDateTime?: string;
    lastCompletedActivity?: CompletedActivity;
    hasActivities?: boolean;
};
export type OrderStates = (
    | 'ACTIVE'
    | 'CANCELED_WAITING_ACTIVITIES_COMPLETION'
    | 'WAITING_CANCEL_ACKNOWLEDGE'
    | 'WAITING_UPDATE_ACKNOWLEDGE'
    | 'IGNORED'
    | 'CANCELLED'
    | 'DONE'
)[];
export type PageOrderResponseDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: OrderResponseDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type PatientActiveInHomeOrdersInfoModel = {
    orders?: PageActiveInHomeInfoModel;
    /** Format: int32 */
    unscheduledActivitiesDueIn72Hours?: number;
    /** Format: int32 */
    unscheduledActivitiesOverdueWithinThePast24Hours?: number;
};
export type PageActiveInHomeInfoModel = {
    /** Format: int64 */
    totalElements?: number;
    /** Format: int32 */
    totalPages?: number;
    /** Format: int32 */
    size?: number;
    content?: ActiveInHomeInfoModel[];
    /** Format: int32 */
    number?: number;
    sort?: string[];
    /** Format: int32 */
    numberOfElements?: number;
    pageable?: PageableObject;
    last?: boolean;
    first?: boolean;
    empty?: boolean;
};
export type ActiveInHomeInfoModel = {
    /** Format: int64 */
    id?: number;
    orderName?: string;
    category?: string;
    flexibleScheduling?: boolean;
    openEnded?: boolean;
    /** Format: date-time */
    acknowledgedDateTime?: string;
    /** Format: date */
    startDate?: string;
    /** Format: date */
    endDate?: string;
    /** @enum {string} */
    frequency?: Frequency;
    originalFrequencyText?: string;
    /** @enum {string} */
    dueStatus?: 'DUE_SOON' | 'OVERDUE' | 'TODAY' | 'TOMORROW' | 'NEXT_DAY';
    /** Format: int32 */
    nursingPriority?: number;
    hasUnscheduledActivities?: boolean;
    hasUnscheduledActivitiesInServiceRequest?: boolean;
    hasAllUnscheduledActivitiesInServiceRequest?: boolean;
    addingActivitiesAllowed?: boolean;
    addingPRNActivitiesAllowed?: boolean;
    /** Format: int32 */
    dueIn72hCount?: number;
    /** Format: int32 */
    overdueWithinPast24hCount?: number;
    /** @enum {string} */
    state?:
        | 'ACTIVE'
        | 'CANCELED_WAITING_ACTIVITIES_COMPLETION'
        | 'WAITING_CANCEL_ACKNOWLEDGE'
        | 'WAITING_UPDATE_ACKNOWLEDGE'
        | 'IGNORED'
        | 'CANCELLED'
        | 'DONE';
    /** Format: date-time */
    cancelEffectiveDateTime?: string;
    clinicalInfo?: string;
    comment?: string;
    /** Format: date-time */
    createdDate?: string;
    ehrEncounterId?: string;
    ehrOrderNumber?: string;
    /** Format: int32 */
    inDayOccurrences?: number;
    primary?: boolean;
    providerFirstName?: string;
    providerLastName?: string;
    lastCompletedActivity?: CompletedActivity;
};
export type NewOrdersCountDTO = {
    newOrderCount?: number;
};
export type ReasonOptionDTO = {
    name?: string;
    commentRequired?: boolean;
};
export type OrderToAcknowledgeResponseDTO = {
    id?: number;
    orderNameCode?: string;
    orderName?: string;
    clinicalInfo?: string;
    comment?: string;
    /** Format: date */
    startDate?: string;
    state?: OrderState;
};
export type MessageHistoryDTO = {
    message?: string;
    data?: string;
    messageType?: 'ADT' | 'ORM' | 'RDE';
    transactionType?: string;
    exception?: string;
    ehrMedicalRecordNumber?: string;
    entityId?: number;
    patientId?: number;
    patientName?: string;
    voided?: boolean;
    patientType?: PatientTypeOptionsDTO;
    couldNotDeterminePrimaryOrSecondary?: boolean;
    matched?: boolean;
    processed?: boolean;
    /** Format: date-time */
    sendTime?: string;
    /** Format: date-time */
    createTime?: string;
    operationType?: 'Create' | 'Cancel' | 'Update' | 'Unknown' | 'Cancel Ignored';
};
export type PageMessageHistoryDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: MessageHistoryDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};

export type ChangingPartnerReasonDTO =
    | 'Service Provider Cancelled Appointment'
    | 'Patient Refused Appointment / Patient Unavailable'
    | 'Post Scheduling Change - New Requirements'
    | 'Service Provider No Show'
    | 'Scheduled in Error'
    | 'Schedule Optimization';

export type HandOffCancelReasonDTO = {
    value?: string;
    commentPresent?: boolean;
};
export type ServiceRequestsCounters = {
    serviceRequests?: {[key: string]: number};
};
export type HandOffCounters = {
    handOffCounters?: {[key: string]: number};
};
export type ActionQueueCounter = {
    count: number;
};
export type AppointmentCancellationServiceRequestResponseDTO = {
    id?: number;
    appointmentId?: number;
    status?: CancellationRequestStatus;
    urgency?: HandOffUrgency;
    patient?: string;
    patientId?: number;
    mcrn?: string;
    /** Format: date-time */
    createdDate?: string;
    /** Format: date */
    firstActivityDate?: string;
    /** Format: date */
    lastActivityDate?: string;
    orderActivity?: OrderActivityDTO[];
    vendor?: string;
    reason?: string;
    comment?: string;
    appointmentReplaced?: boolean;
    patientTimeZone?: string;
};
export type AppointmentCancellationServiceRequestResponseDetailDTO = {
    appointment?: InHomeAppointmentDTO;
    cancellationServiceRequest?: AppointmentCancellationServiceRequestResponseDTO;
    actor?: EligibleVendorActorsSearchResponseDTO;
};
export type ActivityAppointmentItemResponseDTO = {
    id?: number;
    name?: string;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    code?: string;
    orderName?: string;
    orderClinicalInfo?: string;
    orderComment?: string;
    orderFrequency?: string;
    orderId?: number;
};
export type AppointmentItemResponseDTO = {
    id?: number;
    title?: string;
    description?: string;
    startDate?: string;
    startTime?: string;
    endTime?: string;
    showOnPatient?: boolean;
    startDateTime?: string;
    endDateTime?: string;
    miniNotesGenerationSupport?: boolean;
    patient?: PatientCalendarDetailsDTO;
    recurrence?: 'Once' | 'Daily' | 'Every Other Day' | 'Weekly';
    invitationToken?: string;
    clinicalWindowStart?: string;
    clinicalWindowEnd?: string;
    schedulingWindowStart?: string;
    schedulingWindowEnd?: string;
    instruction?: string;
    wheneverFeasible?: boolean;
    workOrderTaskNumber?: string;
};
export type GeneralAppointmentItemResponseDTO = AppointmentItemResponseDTO & {
    state?: 'Scheduled' | 'Started' | 'Completed' | 'Cancelled' | 'Removed';
    generalAppointmentType?:
        | 'Video Visit'
        | 'Phone Call'
        | 'Vital Signs'
        | 'Patient Reminder/Message'
        | 'Facility Visit'
        | 'Other';
    attendees?: (
        | 'Mission Control Nurse'
        | 'Mission Control NP'
        | 'Mission Control PA'
        | 'Mission Control Physician'
        | 'Nurse Practitioner'
        | 'Physician'
    )[];
    type: 'General';
};
export type InHomeAppointmentItemResponseDTO = AppointmentItemResponseDTO & {
    endTime?: string;
    actorType?: string;
    actorName?: string;
    vendorName?: string;
    performer?: PerformerDTO;
    state?: string;
    duration?: string;
    activities?: ActivityAppointmentItemResponseDTO[];
    actualStartTime?: string;
    actualEndTime?: string;
    executedAction?: string;
    status?: InHomeAppointmentStatus;
    allowUndoConfirm?: boolean;
    allowUndoCheckIn?: boolean;
    allowUndoCheckOut?: boolean;
    allowEdit?: boolean;
    replaced?: boolean;
    selfCheckOutConfiguration?:
        | 'Never Allow'
        | 'Allow, confirmation call is always required'
        | 'Allow, confirmation call is not required';
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    type: 'In Home';
};
export type AppointmentItemDTO = InHomeAppointmentItemResponseDTO | GeneralAppointmentItemResponseDTO;

export type PatientCalendarDetailsDTO = {
    id?: number;
    fullName?: string;
    /** Format: date */
    birthDate?: string;
    /** Format: date */
    admitDate?: string;
    hospitalDay?: number;
    genderIdentity?: string;
    phaseOfCare?: string;
    assignedNurse?: string;
    gender?: 'Male' | 'Female' | 'Other' | 'Unknown';
    timeZone?: string;
    primaryLanguage: string;
};
export type AppointmentHistoryChangeDetails = {
    changes?: ChangeDetail[];
};
export type AppointmentStatusHistoryDTO = {
    id?: number;
    event?: string;
    /** Format: date-time */
    eventDateTime?: string;
    personnelFullName?: string;
    changeDetails?: AppointmentHistoryChangeDetails;
};
export type InHomeAppointmentUnplannedActivitiesResponseDTO = {
    appointment?: InHomeAppointmentDTO;
    performer?: PerformerDTO;
    activities?: ActivityDetailsDTO[];
    unplannedActivities?: ActivityDetailsDTO[];
    unplannedTBDActivities?: ActivityDetailsDTO[];
    availablePRNActivities: PRNActivityTemplateDTO[];
    changePartnerRequestId?: number;
};

export type PRNActivityTemplateDTO = {
    templateId?: number;
    orderId?: number;
    cardinality?: number;
    activityName?: string;
    activityCode?: string;
    orderName?: string;
    providerFirstName?: string;
    providerLastName?: string;
    duration?: string;
    label?: string;
};

export type ReviewSelfCheckOutDTO = {
    actorFirstName?: string;
    actorLastName?: string;
    /** Format: date-time */
    arrivalTime?: string;
    /** Format: date-time */
    checkOutTime?: string;
    duration?: string;
    hasAdditionalActivities?: boolean;
    appointment?: InHomeAppointmentDTO;
    performer?: PerformerDTO;
    submittedActivities?: SubmittedActivityDTO[];
    unplannedActivities?: UnplannedActivityDTO[];
};
export type SubmittedActivityDTO = {
    id: number;
    activityName?: string;
    activityCode?: string;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    nurseRequired?: boolean;
    orderId?: number;
    orderName?: string;
    ehrOrderId?: string;
    providerFirstName?: string;
    providerLastName?: string;
    clinicalInfo?: string;
    comment?: string;
    nurseCheckListConfiguration?: ChecklistConfigurationDTO;
    confirmed?: boolean;
    answeredProviderCheckList?: MultiTypeChecklist;
    chargeTransferData?: {code: string; description: string; timeMinutes: string}[];
};
export type ReviewSelfCheckInDTO = {
    actorFirstName?: string;
    actorLastName?: string;
    /** Format: date-time */
    arrivalTime?: string;
    hasAdditionalActivities?: boolean;
    appointment?: InHomeAppointmentDTO;
    performer?: PerformerDTO;
    submittedActivities?: SubmittedActivityDTO[];
    unplannedActivities?: UnplannedActivityDTO[];
};
export type ActivityDocumentDetailsDTO = {
    details?: ActivityDetailsDTO;
    comments?: string;
    /** Format: date-time */
    docReceived?: string;
    docRequired?: boolean;
    docNotReceived?: boolean;
    docNotRequiredByDefault?: boolean;
};
export type CheckInCheckOutDetailsDTO = {
    /** Format: date-time */
    checkIn?: string;
    /** Format: date-time */
    checkOut?: string;
    actor?: string;
};
export type InHomeAppointmentDocumentResponseDTO = {
    appointment?: InHomeAppointmentDTO;
    performer?: PerformerDTO;
    checkInCheckOutDetails?: CheckInCheckOutDetailsDTO;
    activities?: ActivityDocumentDetailsDTO[];
    documentComment?: string;
    missedCheckInOut?: MissedCheckInOutDTO;
};
export type InHomeAppointmentConfirmResponseDTO = {
    activities?: ActivityDetailsDTO[];
    appointment?: InHomeAppointmentDTO;
    performer?: PerformerDTO;
    hasAdditionalActivities?: boolean;
    changePartnerSuggested?: boolean;
    changePartnerRequestId?: number;
    modificationRequestId?: number;
};
export type CheckoutDataResponseDTO = {
    checklist?: ChecklistConfigurationDTO;
    instructions?: string;
    appointment?: InHomeAppointmentDTO;
    activities?: ActivityDetailsDTO[];
    performer?: PerformerDTO;
    actorFirstName?: string;
    actorLastName?: string;
    /** Format: date-time */
    recordedStartTime?: string;
    hasAdditionalActivities?: boolean;
    selfCheckOutStatus?: 'STARTED' | 'WAITING_FOR_REVIEW' | 'CONFIRMED' | 'VOIDED' | 'CANCELLED';
};
export type ActivityCheckInDetailsDTO = {
    activityId?: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    oncePerOrder?: boolean;
    orderId?: number;
    childOrderId?: number;
    orderName?: string;
    orderClinicalInfo?: string;
    orderComment?: string;
    orderNameCode?: string;
    flexibleScheduling?: boolean;
    openEnded?: boolean;
    activityCode?: string;
    category?: string;
    providerFirstName?: string;
    providerLastName?: string;
    duration?: string;
    secondaryDuration?: string;
    activityName?: string;
    description?: string;
    cardinality?: number;
    additionallyAdded?: boolean;
    nurseRequired?: boolean;
    includedInHandOff?: boolean;
    followupAction?: FollowupAction;
    /** Format: date */
    scheduleDate?: string;
    defaultAppointmentArrivalWindow?: string;
    checkinChecklist?: ChecklistConfigurationDTO;
};
export type CheckInResponseDTO = {
    activities?: ActivityCheckInDetailsDTO[];
    appointment?: InHomeAppointmentDTO;
    performer?: PerformerDTO;
    instructions?: string;
    hasAdditionalActivities?: boolean;
    changePartnerSuggested?: boolean;
    changePartnerRequestId?: number;
    selfCheckInStatus?: 'STARTED' | 'WAITING_FOR_REVIEW' | 'CONFIRMED' | 'VOIDED' | 'CANCELLED';
};
export type InHomeAppointmentCalendarDetailsDTO = {
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    /** @example 10:00+00:00 */
    startTime?: string;
    /** @example 11:00+00:00 */
    endTime?: string;
    /** Format: date-time */
    startDateTime?: string;
    /** Format: date-time */
    endDateTime?: string;
    performer?: PerformerDTO;
    actorName?: string;
    stateGroup?: 'Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation';
    state?: string;
    status?: InHomeAppointmentStatus;
    /** Format: date-time */
    actualStartTime?: string;
    /** Format: date-time */
    actualEndTime?: string;
    checkInComment?: string;
    checkInRating?: number;
    checkOutComment?: string;
    checkOutRating?: number;
    missedCheckInOutReason?: string;
    missedCheckInOutComment?: string;
    cancellationReason?: string;
    cancellationComment?: string;
    cancellationRequestId?: number;
    patient?: PatientCalendarDetailsDTO;
    doneTimingOption?: 'ON_TIME' | 'LATE';
    templateCode?: 'PLANNED' | 'CHECKED_IN' | 'COMPLETED' | 'PENDING_CANCELLATION' | 'MISSED_CHECK_IN_OUT';
    activities?: ActivityAppointmentItemResponseDTO[];
    editAllowed?: boolean;
};
export type InHomeAppointmentAgendaDetailsDTO = {
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    /** @example 10:00+00:00 */
    startTime?: string;
    /** @example 11:00+00:00 */
    endTime?: string;
    /** Format: date-time */
    startDateTime?: string;
    /** Format: date-time */
    endDateTime?: string;
    performer?: PerformerDTO;
    actorName?: string;
    stateGroup?: 'Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation';
    state?: string;
    status?: InHomeAppointmentStatus;
    /** Format: date-time */
    actualStartTime?: string;
    /** Format: date-time */
    actualEndTime?: string;
    checkInComment?: string;
    checkInRating?: number;
    checkOutComment?: string;
    checkOutRating?: number;
    missedCheckInOutReason?: string;
    missedCheckInOutComment?: string;
    cancellationReason?: string;
    cancellationComment?: string;
    cancellationRequestId?: number;
    patient?: PatientCalendarDetailsDTO;
    doneTimingOption?: 'ON_TIME' | 'LATE';
    templateCode?: 'PLANNED' | 'CHECKED_IN' | 'COMPLETED' | 'PENDING_CANCELLATION' | 'MISSED_CHECK_IN_OUT';
    activities?: ActivityAppointmentItemResponseDTO[];
    nextState?: string;
    allowUndoConfirm?: boolean;
    allowUndoCheckIn?: boolean;
    allowUndoCheckOut?: boolean;
    allowEdit?: boolean;
    editAllowed?: boolean;
};
export type AppointmentPreDocumentedOverviewDTO = {
    id?: number;
    startDate?: string;
    checkInTime?: string;
    patientBirthDate?: string;
    patientId?: number;
    patientMrn?: string;
    patientTimeZone?: string;
    actor?: string;
    site?: string;
    nextAction?: string;
    patientFullName?: string;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    startTime?: string;
    endTime?: string;
    startDateTime?: string;
    endDateTime?: string;
    patientType?: PatientTypeOptionsDTO;
    wheneverFeasible?: boolean;
    workOrderTaskNumber?: string;
};
export type PageAppointmentPreDocumentedOverviewDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: AppointmentPreDocumentedOverviewDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};
export type PreDocumentAppointmentsCountDTO = {
    preDocumentAppointmentsCount?: number;
};
export type GeneralAppointmentResponseDTO = {
    id?: number;
    patient?: PatientCalendarDetailsDTO;
    title: string;
    description?: string;
    /**
     * Format: date
     * @example 2019-10-24
     */
    startDate: string;
    /** @example 10:00+00:00 */
    startTime: string;
    /** @example 11:00+00:00 */
    endTime: string;
    /** Format: date-time */
    startDateTime?: string;
    /** Format: date-time */
    endDateTime?: string;
    /**
     * Format: date
     * @example 2019-10-28
     */
    endOfRecurrence?: string;
    recurrence: 'Once' | 'Daily' | 'Every Other Day' | 'Weekly';
    showOnPatient?: boolean;
    timeRangeAppointment?: boolean;
    state: 'Scheduled' | 'Started' | 'Complete' | 'Cancelled' | 'Removed';
    generalAppointmentType?: string;
    attendees?: Attendees[];
    createInvitationLink?: boolean;
    startWhenLinkUsed?: boolean;
    invitationToken?: string;
};
export type AppointmentCalendarItemDTO = {
    generalAppointmentType?:
        | 'Video Visit'
        | 'Phone Call'
        | 'Vital Signs'
        | 'Patient Reminder/Message'
        | 'Facility Visit'
        | 'Other';
    state?: 'Planned' | 'Ongoing' | 'Completed' | 'Pending Cancellation';
    status?: string;
    startTime?: string;
    startDateTime?: string;
    endTime?: string;
    endDateTime?: string;
    startDate?: string;
    timeCalculated?: boolean;
    timeZone?: string;
    id?: number;
    arrivalWindow?: string;
    manualArrivalWindow?: boolean;
    expectedDuration?: string;
    type?: string;
    patientId?: number;
    title?: string;
};
export type TBDActivityScheduleRequestDTO = {
    scheduleDate: string;
    carePlanId?: number;
    version?: number;
};
export type ActivityData = {
    templateId: number;
    planDate: string;
};
export type PlanOrderPRNActivitiesRequestDTO = {
    plannedActivities: ActivityData[];
};
export type TBDActivityOrderCancelRequest = {
    activityId: number;
    isRequired: boolean;
    scheduleOnDate?: string;
    notRequiredComment?: string;
    required?: boolean;
};
export type OrderCancellationDetail = {
    activityIdsToCancel?: number[];
    tbdRequests?: TBDActivityOrderCancelRequest[];
    id?: number;
};
export type AppointmentCalendarResponseDTO = {
    patients?: PatientCalendarItemDTO[];
    appointments?: AppointmentCalendarItemDTO[];
};
export type PatientCalendarItemDTO = {
    id?: number;
    fullName?: string;
    timeZone?: string;
    gender?: 'MALE' | 'FEMALE' | 'OTHER' | 'UNKNOWN';
    preferredName?: string;
    birthDate?: string;
    mrn?: string;
    patientType?: PatientTypeOptionsDTO;
};
export type AppointmentCalendarFilterItemDTO = {
    id?: number;
    label?: string;
    type?: 'PERSONNEL' | 'POD';
};
export type ActorTypeResponseDTO = {
    code?: string;
    name?: string;
};
export type ActivityStatusHistoryResponseDTO = {
    status?: string;
    vendor?: string;
    actor?: string;
    /** Format: date-time */
    dateTime?: string;
    /** Format: date-time */
    recorded?: string;
    additionalStatus?: string;
    personnelFullname?: string;
    comment?: string;
    doNotNeedToBeRescheduled?: boolean;
    onAction?: string;
    additionalData?: {[key: string]: {[key: string]: unknown}};
};
export type ActivityRequiredHistory = {
    /** Format: date-time */
    createdDate?: string;
    createdBy?: string;
    comment?: string;
    required?: boolean;
};

export type AuthDTO = {
    domain?: string;
    clientId?: string;
    audience?: string;
};
export type BuildInfo = {
    version?: string;
    time?: string;
};
export type RemoveDeviceFromEquipmentKitRequestDTO = {
    deviceId?: number;
    deviceStatus?: DeviceStatus;
    comment?: string;
};
export type TownDTO = {
    id?: number;
    state: string;
    name: string;
};

export type PageTownDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: TownDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};

export type NeighborhoodDTO = {
    id?: number;
    state: string;
    name: string;
    partOf: string;
};

export type PageNeighborhoodDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: NeighborhoodDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};

export type ActivityInHomeDetailsResponseDTO = {
    id?: number;
    title?: string;
    name?: string;
};

export type InHomeDetailsResponseDTO = {
    id?: number;
    title?: string;
    vendorName?: string;
    timeZone?: string;
    siteCode?: string;
    startDate?: string;
    startDateTime?: string;
    endDateTime?: string;
    actorName?: string;
    status?: string;
    activities?: ActivityInHomeDetailsResponseDTO[];
};

export type GetActiveInHomeOrdersQueryDTO = {
    'patient-id'?: number;
    categories?: string[];
    'start-date'?: string;
    'end-date'?: string;
    'name-or-id'?: string;
    'only-due-in-72h'?: boolean;
    sort?: string | string[];
};

export type InHomeAppointmentReferralRequestDTO = {
    sent?: string;
    accepted?: string;
};

// getAll_8 in BE DTOs
export type MessageHistoryFilterDTO = {
    message_types?: ('ADT' | 'ORM' | 'RDE')[];
    sent_date_from?: string;
    sent_date_to?: string;
    create_date_from?: string;
    create_date_to?: string;
    with_errors?: boolean;
    mrn?: string;
    transaction_type?: string;
    page?: number;
    size?: number;
    sort?: string[];
};

export type PageActivityWidgetResponse = {
    totalPages?: number;
    totalElements?: number;
    size?: number;
    content?: ActivityWidgetResponse[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: Pageable;
    last?: boolean;
    first?: boolean;
    empty?: boolean;
};

export type ActivityWidgetResponse = {
    activity?: ActivityWidgetModel;
    order?: OrderWidgetModel;
    patient?: PatientWidgetModel;
};

export type ActivityWidgetModel = {
    id?: number;
    name?: string;
    dueStatus?: 'DUE_SOON' | 'OVERDUE' | 'TODAY' | 'TOMORROW' | 'NEXT_DAY';
    dueDate?: string;
};

export type OrderWidgetModel = {
    id?: number;
    name?: string;
    startDate?: string;
    endDate?: string;
};
export type SendAppointmentRequestDTO = {
    handOffVendorId: number;
    visitType: string;
    clinicalInfo: string;
    arrivalTime: string;
};

export type LastAppointmentRequestDTO = {
    visitType: string;
    clinicalInfo: string;
    arrivalDateTime: string;
};

export type AppointmentRequestDetailModel = {
    primaryDiagnosis?: AppointmentRequestDetailModelDiagnosis;
    diagnoses?: AppointmentRequestDetailModelDiagnosis[];
    precautions?: AppointmentRequestDetailModelPrecaution[];
    orders?: AppointmentRequestDetailModelOrder[];
};

export type AppointmentRequestDetailModelDiagnosis = {
    text?: string;
};

export type AppointmentRequestDetailModelPrecaution = {
    name?: string;
    startDate?: string;
    endDate?: string;
};

export type PatientWidgetModel = {
    id?: number;
    fullName?: string;
};

// Comes from websocketService
export type LoggedInUser = {
    firstName?: string;
    lastName?: string;
    heartbeatTime?: string;
    logoutTime?: string;
    loginTime?: string;
    currentStatus?: string;
    statusTime?: string;
    active?: boolean;
    login?: string;
    routingProfile?: string;
};

export type ReferralDatesRequestDTO = {
    sentDateTime: string;
    acceptedDateTime: string;
};

export type ReferralDateRequestDTO = {
    dateTime?: string;
};

export type WifiNetworkSpeedDataResponse = {
    downloadSpeed?: number;
    uploadSpeed?: number;
    ping?: number;
    dateTime?: string;
    rssi?: string;
    networkTestType?: 'WIFI_SETUP' | 'CRADLEPOINT';
};

export type WifiNetworkResponse = {
    id?: number;
    equipmentKitId?: number;
    networkName?: string;
    dateTime?: string;
    networkStatus?: 'NOT_CONNECTED' | 'CONNECTED' | 'VALIDATING' | 'VALIDATED' | 'FAILED' | 'DISCONNECTED';
    cradlepointStatus?: 'NOT_CONFIGURED' | 'CONFIGURED' | 'SUBMITTED';
    ip?: string;
    password?: string;
    bssid?: string;
    speedDataList?: WifiNetworkSpeedDataResponse[];
    patientId?: number;
    active?: boolean;
};

export type RoutingProfileDTO = {
    type: string;
    name: string;
};
export type AppointmentRequestDetailModelOrder = {
    orderName?: string;
    clinicalInfo?: string;
    comment?: string;
    activities?: AppointmentRequestDetailModelOrderActivity[];
};

export type AppointmentRequestDetailModelOrderActivity = {
    name?: string;
};

export type AppointmentVisitTypesDTO = string[];

export type NurseAssignmentsDTO = {
    nurses?: SimplePersonnelDTO[];
    depotTechnicians?: SimplePersonnelDTO[];
};

export type SimplePersonnelDTO = {
    id: number;
    email: string;
    preferredEmail?: string;
    firstName: string;
    lastName: string;
};

export type VendorActorKey = {
    vendorCode?: string;
    siteCode?: string;
    actorCode?: string;
};

export type Schedule = {
    daySchedule?: {[key: string]: DaySchedule};
};

export type ZoomShareableVideoCallDetailsDTO = {
    patientInitials: string;
    primaryLanguage: string;
    startDate: string;
    startTime: string;
    endTime: string;
};

export type AddShareableVideoCallAttendeeDTO = {
    fullName: string;
    email: string;
    preferredEmail?: string;
    firstName: string;
    lastName: string;
};

export type PostponedNotificationDTO = {
    uuid?: string;
    message?: string;
};

export type AcknowledgeOrderCancelDTO =
    | 'ACTIVE'
    | 'CANCELED_WAITING_ACTIVITIES_COMPLETION'
    | 'WAITING_CANCEL_ACKNOWLEDGE'
    | 'WAITING_UPDATE_ACKNOWLEDGE'
    | 'IGNORED'
    | 'CANCELLED'
    | 'DONE';

export type TBDActivityOrderCancelRequestDTO = {
    activityId: number;
    isRequired: boolean;
    scheduleOnDate?: string;
    notRequiredComment?: string;
    required?: boolean;
};

export type OrderCancellationDetailDTO = {
    activityIdsToCancel?: number[];
    tbdRequests?: TBDActivityOrderCancelRequestDTO[];
};
export type ModificationRequestPatientModel = {
    id?: number;
    timeZone?: string;
    name?: string;
    demo?: boolean;
    type?: PatientTypeOptionsDTO;
};

export type VendorStatus =
    | 'Vendor not contacted'
    | 'Initiated communication'
    | 'One or more vendors not available'
    | 'Referral sent with orders still pending'
    | 'Referral document with orders sent'
    | 'Confirmed referral receipt'
    | 'N/A';

export type AppointmentModificationRequestListDTO = {
    id?: number;
    startTime?: string;
    startDate?: string;
    tbd?: boolean;
    endTime?: string;
    urgency?: HandOffUrgency;
    status?: HandOffStatus;
    vendorStatus?: VendorStatus;
    vendorSiteName?: string;
    precautions?: string[];
    createdDate?: string;
    patient?: ModificationRequestPatientModel;
    serviceCoordinator?: string;
    modified?: boolean;
    appointmentCancelled?: boolean;
};
export type PageAppointmentModificationRequestListDTO = {
    totalElements?: number;
    totalPages?: number;
    size?: number;
    content?: AppointmentModificationRequestListDTO[];
    number?: number;
    sort?: string[];
    numberOfElements?: number;
    pageable?: PageableObject;
    first?: boolean;
    last?: boolean;
    empty?: boolean;
};

export type ModificationRequestActivityDetailsModel = {
    id?: number;
    label?: string;
    activityName?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    orderName?: string;
    orderId?: number;
    modificationDateTime?: string;
    modificationSource?: 'EDIT_APPOINTMENT' | 'ORDER_CANCELLATION' | 'CONFIRM_APPOINTMENT' | 'CARE_PLAN' | 'CHECK_IN';
    modificationType?: 'ACTIVITY_ADDED' | 'ACTIVITY_REMOVED';
    vendorDecision?: ModificationRequestActivityVendorDecisionModel;
    modifiedBy?: ModificationRequestActivityModifierModel;
};
export type ModificationRequestActivityVendorDecisionModel = {
    modificationResult?: 'VENDOR_ACCEPTED' | 'VENDOR_DISCARDED';
    refuseReason?: string;
};
export type ModificationRequestActivityModifierModel = {
    firstName?: string;
    lastName?: string;
};

export type AppointmentModificationRequestDetailsModel = {
    id?: number;
    urgency?: HandOffUrgency;
    status?: 'Not started' | 'In progress' | 'Not fulfilled' | 'Completed' | 'Cancelled';
    modified?: boolean;
    activityModificationCount?: number;
    vendorStatus?: VendorStatus;
    referralSentDate?: string;
    referralAcceptedDate?: string;
    patient?: ModificationRequestPatientModel;
    performer?: PerformerDTO;
    appointment?: ModificationRequestAppointmentModel;
    activities?: ModificationRequestActivityModel[];
    updatedActivities?: ModificationRequestActivityModel[];
};

export type OldDataDetails = {
    appointmentDuration: string;
    earliestTime: string;
    latestTime: string;
    instruction: string;
    wheneverFeasible: boolean;
    lastModifiedBy: string;
    lastModifiedAt: string;
};

export type ModificationSchedulingDetailsDTO = {
    appointmentId?: number;
    patientId?: number;
    instruction?: string;
    earliestTime?: string;
    latestTime?: string;
    orders?: HandOffOrderDTO[];
    mcrnfullName?: string;
    changeReason?: string;
    appointmentDuration?: string;
    arrivalWindow?: string;
    schedulingWindowStart?: string;
    schedulingWindowEnd?: string;
    wheneverFeasible?: boolean;
    oldDataDetails?: OldDataDetails;
};

export type AppointmentNoteModel = {
    text?: string;
};

export type ModificationRequestAppointmentModel = {
    id?: number;
    startDate?: string;
    startTime?: string;
    arrivalWindow?: string;
    expectedDuration?: string;
    note?: AppointmentNoteModel;
    manualDuration?: boolean;
    manualArrivalWindow?: boolean;
};

export type ModificationRequestActivityModel = {
    id?: number;
    label?: string;
    inDayOccurrence?: number;
    inDayOccurrences?: number;
    orderName?: string;
    modificationDateTime?: string;
    modificationSource?: 'EDIT_APPOINTMENT' | 'ORDER_CANCELLATION' | 'CONFIRM_APPOINTMENT' | 'CHECK_IN';
    modificationType?: 'ACTIVITY_ADDED' | 'ACTIVITY_REMOVED';
    vendorDecision?: ModificationRequestActivityVendorDecisionModel;
    modifiedBy?: ModificationRequestActivityModifierModel;
    specification?: ActivitySpecificationDTO;
};

export type ActivitySpecificationModel = {
    name?: string;
    code?: string;
    expectedDuration?: string;
    expectedDurationSecondary?: string;
    defaultAppointmentArrivalWindow?: string;
    maxEarlyArrival?: string;
    showOnPatient?: boolean;
};

export type AppointmentModificationRequestCompleteDTO = {
    startTime?: string;
    duration?: string;
    arrivalWindow?: string;
    activitiesInfo?: ModificationRequestActivityDecisionModel[];
};

export type ModificationRequestActivityDecisionModel = {
    id?: number;
    modificationResult?: 'VENDOR_ACCEPTED' | 'VENDOR_DISCARDED';
    refuseReason?: string;
};

export type AddUnplannedActivitiesResponseDTO = {
    activityIds?: number[];
    notificationId?: string;
};

export type SimpleMessageResponseWithNotificationIdDTO = {
    notificationId?: string;
    status?: 'OK';
    message?: string;
};

export type AcknowledgeOrderCancelResponseDTO = {
    notificationId?: string;
    orderState?:
        | 'ACTIVE'
        | 'CANCELED_WAITING_ACTIVITIES_COMPLETION'
        | 'WAITING_CANCEL_ACKNOWLEDGE'
        | 'WAITING_UPDATE_ACKNOWLEDGE'
        | 'IGNORED'
        | 'CANCELLED'
        | 'DONE';
};

export type PostponedNotificationIdResponseDTO = {
    notificationId?: string;
};

export type CompletedActivity = {
    name?: string;
    label?: string;
    completedAt?: string;
};

export type ModificationSRRequestModel = {
    vendorStatuses?: VendorSubstatus[];
    statuses?: HandOffStatus[];
    serviceCoordinators?: number[];
    vendorCodes?: string[];
    sponsors?: string[];
    pods?: number[];
    includeDemo?: boolean;
};

export type CancellationSRRequestModel = {
    urgencies?: ('Stat' | 'ASAP' | 'Routine')[];
    statuses?: ('Pending Cancellation' | 'Completed')[];
    pods?: number[];
    includeDemo?: boolean;
    sponsors?: string[];
    serviceCoordinators?: number[];
    vendorCodes?: string[];
};

export type OutstandingDocumentationRequestModel = {
    patientIds?: number[];
    siteCodes?: string[];
    includeDemo?: boolean;
};

export type SchedulingSRRequestModel = {
    urgencies?: ('Stat' | 'ASAP' | 'Routine')[];
    statuses?: HandOffStatus[];
    pods?: number[];
    includeDemo?: boolean;
    admission?: boolean;
    sponsors?: string[];
    /** @enum {string} */
    requestType?: 'SCHEDULING' | 'CHANGE_VENDOR';
    serviceCoordinators?: number[];
    vendorCodes?: string[];
    vendorStatuses?: VendorStatus[];
    /** Format: date */
    appointmentScheduledFrom?: string;
    /** Format: date */
    appointmentScheduledTo?: string;
    patientQuery?: string;
    timesOfDay?: TimeOfDay[];
    discharged?: boolean;
};

export type NonKitDeviceSpecification = {
    id: string;
    type: string;
    name: string;
    unit: string;
    values: number[];
    active: boolean;
};

export type NonKitDeviceResponseDTO = {
    nonKitDeviceSpecificationId: string;
    name: string;
    value: number;
    unit: string;
    active: boolean;
    duration?: number;
    lastUpdated: string;
};

export type NonKitDeviceRequestDTO = {
    nonKitDeviceSpecificationId: string;
    name: string;
    value: number;
    unit: string;
    active?: boolean;
};

export type CylinderDurationRequestDTO = {
    patientLpm: number;
    cylinderPsiValue: number;
};

export type CylinderDurationResponseDTO = {
    patientLpm: number;
    cylinderPsiValue: number;
    duration: number;
};

export type PatientCalculateEcylinderDurationRequestDTO = {
    patientLpm: number;
};

export type VoidedReason =
    | 'Deceased'
    | 'Duplicate Patient'
    | 'Duplicate Admission'
    | 'Insurance Change'
    | 'Patient Elected to opt out'
    | 'Patient Moved'
    | 'Patient Not Admitted'
    | 'Void Demo Patient'
    | 'Wrong Patient';

export type PersonnelBasicDTO = {
    id: string;
    firstName: string;
    lastName: string;
};

export type UnviewedMissedCallsCountDTO = {
    unviewedMissedCallsCount: number;
};
export type TimeOfDay = 'MORNING' | 'AFTERNOON' | 'EVENING' | 'NIGHT' | 'WHENEVER_FEASIBLE';

export type PersonnelMovingToClusterRequest = {
    personnelIds: number[];
    fromCluster?: number;
    toCluster?: number;
};

export type AssignedCluster = {
    id?: number;
    name?: string;
};

export type PersonnelAssigmentResult = {
    personnel?: PersonnelDTO;
    finalClusters?: AssignedCluster[];
    errors?: ('ABSENT_IN_SOURCE_CLUSTER' | 'ALREADY_IN_TARGET_CLUSTER' | 'PATIENT_ASSIGMENT' | 'LICENSE_LIMITATION')[];
};

export type PersonnelMovingToClusterResponse = {
    results: PersonnelAssigmentResult[];
    errors: ('ABSENT_IN_SOURCE_CLUSTER' | 'ALREADY_IN_TARGET_CLUSTER' | 'PATIENT_ASSIGMENT' | 'LICENSE_LIMITATION')[];
};

export type BarcodeViewDTO = {
    id: number;
    base64Image: string;
    createdDate: string;
    isReportable: boolean;
    originalData: string;
};

export type ReportBarcodeDTO = {
    createdAt: string;
    timeZone: string;
    base64Image: string;
    originalData: string;
    comment: string;
};

export type AneltoBatteryStatusResponseDTO = {
    wearableId: string;
    type: 'HALO' | 'WEARABLE';
    battery: number;
    timestamp: string;
    tzOffset: number;
    batteryIndicator: 'RED' | 'GREEN';
};

export type ClusterUpdateHandleUnknownCallersDTO = {
    handleUnknownCallers: boolean;
};
