import {
    CP_DEFAULT_SHOW_CANCELLED_APPTS,
    VIDEO_CUSTOM_BG_BASE64_SRC,
    VIDEO_DEFAULT_CAMERA_ID,
    VIDEO_DEFAULT_CAMERA_TITLE,
    VIDEO_DEFAULT_MIC_ID,
    VIDEO_DEFAULT_MIC_TITLE,
    VIDEO_DEFAULT_SPEAKER_ID,
    VIDEO_DEFAULT_SPEAKER_TITLE,
    VIDEO_FILTER_OPTION,
    VIDEO_IS_CUSTOM_BG_SELECTED,
    VIDEO_IS_FILTER_SELECTED,
} from '@/constants/constants';
import {PatientResponseDTO} from '@/types/gatewayDataModels';

const prefix = '';

const setParamToLocalStorage = <V>(param: string, value: V) =>
    localStorage.setItem(`${prefix}${param}`, JSON.stringify(value));

const getParamFromLocalStorage = <V = any>(name: string, defaultValue?: V): V => {
    try {
        return JSON.parse(localStorage[name]);
    } catch (e) {
        return defaultValue;
    }
};

const addUserToLocalStorage = (data: PatientResponseDTO) => {
    try {
        const lastPatients = getParamFromLocalStorage('lastPatients', []);
        const patient = lastPatients.find((item) => item.id === data.id);

        if (patient) lastPatients.splice(lastPatients.indexOf(patient), 1);
        if (lastPatients.length > 14) lastPatients.pop();

        lastPatients.unshift(data);
        localStorage.setItem('lastPatients', JSON.stringify(lastPatients));
    } catch (e) {
        localStorage.removeItem('lastPatients');
    }
};

const clearLocalStorage = () => {
    const missionControls = localStorage.getItem('missionControls');
    const deviceSpeaker = localStorage.getItem(VIDEO_DEFAULT_SPEAKER_ID);
    const deviceSpeakerTitle = localStorage.getItem(VIDEO_DEFAULT_SPEAKER_TITLE);
    const deviceCamera = localStorage.getItem(VIDEO_DEFAULT_CAMERA_ID);
    const deviceCameraTitle = localStorage.getItem(VIDEO_DEFAULT_CAMERA_TITLE);
    const deviceMic = localStorage.getItem(VIDEO_DEFAULT_MIC_ID);
    const deviceMicTitle = localStorage.getItem(VIDEO_DEFAULT_MIC_TITLE);
    const videoIsFilterSelected = localStorage.getItem(VIDEO_IS_FILTER_SELECTED);
    const videoFilterOptioon = localStorage.getItem(VIDEO_FILTER_OPTION);
    const videoCustomBgBase64Src = localStorage.getItem(VIDEO_CUSTOM_BG_BASE64_SRC);
    const videoIsCustomBgSelected = localStorage.getItem(VIDEO_IS_CUSTOM_BG_SELECTED);
    const showCancelledAppts = localStorage.getItem(CP_DEFAULT_SHOW_CANCELLED_APPTS);
    localStorage.clear();
    missionControls && localStorage.setItem('missionControls', missionControls);
    deviceSpeaker && localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, deviceSpeaker);
    deviceSpeakerTitle && localStorage.setItem(VIDEO_DEFAULT_SPEAKER_TITLE, deviceSpeakerTitle);
    deviceCamera && localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, deviceCamera);
    deviceCameraTitle && localStorage.setItem(VIDEO_DEFAULT_CAMERA_TITLE, deviceCameraTitle);
    deviceMic && localStorage.setItem(VIDEO_DEFAULT_MIC_ID, deviceMic);
    deviceMicTitle && localStorage.setItem(VIDEO_DEFAULT_MIC_TITLE, deviceMicTitle);
    videoIsFilterSelected && localStorage.setItem(VIDEO_IS_FILTER_SELECTED, videoIsFilterSelected);
    videoFilterOptioon && localStorage.setItem(VIDEO_FILTER_OPTION, videoFilterOptioon);
    videoCustomBgBase64Src && localStorage.setItem(VIDEO_CUSTOM_BG_BASE64_SRC, videoCustomBgBase64Src);
    videoIsCustomBgSelected && localStorage.setItem(VIDEO_IS_CUSTOM_BG_SELECTED, videoIsCustomBgSelected);
    showCancelledAppts && localStorage.setItem(CP_DEFAULT_SHOW_CANCELLED_APPTS, showCancelledAppts);
};

export {addUserToLocalStorage, clearLocalStorage, getParamFromLocalStorage, setParamToLocalStorage};
